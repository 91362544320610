import {createAction, props} from '@ngrx/store';

export const loadSkills = createAction(
  '[Common] Load Skills'
);

export const loadSkillsSuccess = createAction(
  '[Common] Load Skills Success',
  props<{ data: any }>()
);

export const loadSkillsFailure = createAction(
  '[Common] Load Skills Failure',
  props<{ error: any }>()
);
