import {ChangeDetectorRef, Injectable} from "@angular/core";
import {DepartmentService} from "./department.service";
import {Store} from "./store";

@Injectable({
  providedIn: 'root',
})
export class DepartmentsStore extends Store<[]|null> {

  constructor (private departmentService: DepartmentService, private ref: ChangeDetectorRef) {
    super(null);
  }

  get(): void {
    this.departmentService.getAll().subscribe(data => {
      this.setState(data);
      this.ref.markForCheck()
    });
  }

  getWithJobs(): void {
    this.departmentService.getWithJobs().subscribe(data => {
      this.setState(data);
      this.ref.markForCheck()
    });
  }

  remove(id: any): void {
    const departments: [] = <[]>this.state

    if (departments) {
      departments.forEach((department: any) => {
        department.jobs = department.jobs.filter((job: any) => {
          return job.id != id
        })
      })
    }

    this.setState([...departments])
    this.ref.markForCheck()
  }

  add(job: any): void {
    let departments = this.state

    if (departments) {
      departments.forEach((department: any) => {
        if (department.id == job.department_id) {
          department.jobs.push(job)
        }
      })
    }

    this.setState(Object.assign([], departments))
    this.ref.markForCheck()
  }
}
