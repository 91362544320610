<form [formGroup]="form">
  <div class="input-group flex-nowrap search" [ngClass]="{'is-invalid' : (city.dirty || city.touched) && city.errors}">
    <span class="input-group-text"><fa-icon [icon]="fa.faSearch"></fa-icon></span>
    <input type="text" #addressText class="form-control"
           ngx-google-places-autocomplete
           [options]="options"
           (onAddressChange)="onAddressChange($event)"
           placeholder="{{placeholder}}"
    />
  </div>
  <div *ngIf="city?.errors" class="invalid-feedback">
    <div *ngIf="city?.hasError('required')">
      Ce champ est obligatoire
    </div>
    <div *ngIf="city?.errors?.['serverError']">
      {{city.errors?.['serverError']}}
    </div>
  </div>

  <div class="d-flex" *ngIf="displayPreview">
    <ng-container *ngIf="type === 'city' || type === 'city_perimeter'">
      <div *ngIf="displayResult && city && city.value && city.value.length" class="mt-2">
        <div class="tag tag-secondary selected pointer" *ngIf="city.value" (click)="remove()">
        <span class="d-flex gap-2">
          <div>
            {{city.value}}
          </div>
          <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
        </span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 'zipCode'">
      <div *ngIf="displayResult && zipCode && zipCode.value && zipCode.value.length" class="mt-2">
        <div class="tag tag-secondary selected pointer" *ngIf="zipCode.value" (click)="remove()">
          <div class="d-flex gap-2">
            <div>
              <span>{{city.value}}<span *ngIf="zipCode.value">, {{zipCode.value}}</span></span>
            </div>
            <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 'address'">
      <div *ngIf="displayResult && address && address.value && address.value.length" class="mt-2">
        <div class="tag tag-secondary selected pointer" *ngIf="address.value" (click)="remove()">
          <div class="d-flex gap-2">
            <div>
              {{address.value}}<span *ngIf="city.value">, {{city.value}}</span><span *ngIf="zipCode.value">, {{zipCode.value}}</span>
            </div>
            <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="type === 'city_perimeter' && city.value" class="my-2">
    <label class="form-label">Rayon (Kilomètres)</label>
    <div class="custom-slider me-1" >
      <ngx-slider formControlName="perimeter" #slider [options]="optionsPerimeter"></ngx-slider>
    </div>
  </div>

</form>


