import {createAction, props} from '@ngrx/store';
import {SubscriberSearchModel} from "../models/subscriberSearch.model";

export const loadSearches = createAction(
  '[SubscriberSearch] Load SubscriberSearch'
);

export const loadSearchesSuccess = createAction(
  '[SubscriberSearch] Load SubscriberSearch Success',
  props<{ subscriberSearches: SubscriberSearchModel[] }>()
);

export const loadSearchesFailure = createAction(
  '[SubscriberSearch] Load SubscriberSearch Failure',
  props<{ error: any }>()
);

export const setCurrentSubscriberSearch = createAction(
  '[SubscriberSearch] Set Current SubscriberSearch',
  props<{ subscriberSearch: SubscriberSearchModel | null }>()
);

export const storeSubscriberSearch = createAction(
  '[SubscriberSearch] Store SubscriberSearch',
  props<{ subscriberSearch: SubscriberSearchModel }>()
);

export const storeSubscriberSearchSuccess = createAction(
  '[SubscriberSearch] Store SubscriberSearch Success',
  props<{ subscriberSearch: SubscriberSearchModel }>()
);

export const storeSubscriberSearchFailure = createAction(
  '[SubscriberSearch] Store SubscriberSearch Failure',
  props<{ error: any }>()
);

export const updateSubscriberSearch = createAction(
  '[SubscriberSearch] Update SubscriberSearch',
  props<{subscriberSearch: SubscriberSearchModel}>()
);

export const updateSubscriberSearchSuccess = createAction(
  '[SubscriberSearch] Update SubscriberSearch Success',
  props<{ subscriberSearch: SubscriberSearchModel }>()
);

export const updateSubscriberSearchFailure = createAction(
  '[SubscriberSearch] Update SubscriberSearch Failure',
  props<{ error: any }>()
);

export const deleteSubscriberSearch = createAction(
  '[SubscriberSearch] Delete SubscriberSearch',
  props<{subscriberSearch: SubscriberSearchModel}>()
);

export const deleteSubscriberSearchSuccess = createAction(
  '[SubscriberSearch] Delete SubscriberSearch Success',
  props<{ data: any }>()
);

export const deleteSubscriberSearchFailure = createAction(
  '[SubscriberSearch] Delete SubscriberSearch Failure',
  props<{ error: any }>()
);

