import {NgModule} from '@angular/core';
import {FormJobsComponent} from "./form-jobs.component";
import {SharedModule} from "../../shared.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgSelectModule} from "@ng-select/ng-select";

@NgModule({
  imports: [SharedModule, FontAwesomeModule, NgSelectModule],
  declarations: [FormJobsComponent],
  exports: [FormJobsComponent],
})
export class FormJobsModule {}
