import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as SearchActions from '../actions/search.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {SearchService} from "../apis/search.service";
import {Store as StoreNgrx} from "@ngrx/store";

@Injectable()
export class SearchEffects {
  constructor(private actions$: Actions, private searchService: SearchService, private storeNgrx: StoreNgrx) {
  }

  load$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SearchActions.loadSearch),
        map((action: any) => action),
        mergeMap(({body}) => {
          if (body.page && body.page === 1) {
            this.storeNgrx.dispatch(SearchActions.resetSearch())
          }
          return this.searchService.search(body).pipe(
            map(data => SearchActions.loadSearchSuccess({data})),
            catchError(error => of(SearchActions.loadSearchFailure({error})))
          );
        })
      )
    }
  );
}
