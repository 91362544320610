<ul class="nav nav-pills nav-flush d-flex flex-column gap-2 mt-4">
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/profile/synthesis" [ariaCurrentWhenActive]="'page'" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'"
       [routerLinkActive]="'active'">
      <fa-icon [icon]="fa.faIdCard"></fa-icon>
      <div class="d-none d-lg-block">Profil</div>
      <div class="d-lg-none">Profil</div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/profile/job-search" [routerLinkActive]="'active'" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'">
      <fa-icon [icon]="fa.faFileMagnifyingGlass"></fa-icon>
      <div class="d-none d-lg-block">Recherche de job</div>
      <div class="d-lg-none">Recherche de job</div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/profile/qualification"[attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'"
       [routerLinkActive]="'active'">
      <fa-icon [icon]="fa.faLightbulbOn"></fa-icon>
      <div class="d-none d-lg-block">Compétences</div>
      <div class="d-lg-none">Compétences</div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/profile/additional-info" [routerLinkActive]="'active'" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'">
      <fa-icon [icon]="fa.faPaperclip"></fa-icon>
      <div class="d-none d-lg-block">Infos Complémentaires</div>
      <div class="d-lg-none">Infos Complémentaires</div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/profile/experiences" [routerLinkActive]="'active'" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'">
      <fa-icon [icon]="fa.faBriefcase"></fa-icon>
      <div class="d-none d-lg-block">Expériences & formations</div>
      <div class="d-lg-none">Expériences & formations</div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/profile/questions" [routerLinkActive]="'active'" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'">
      <fa-icon [icon]="fa.faCommentLines"></fa-icon>
      <div class="d-none d-lg-block">Parlez-nous de vous</div>
      <div class="d-lg-none">Parlez-nous de vous</div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/profile/recommendations" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'"
       [routerLinkActive]="'active'">
      <fa-icon [icon]="fa.faHandshakeSimple"></fa-icon>
      <div class="d-none d-lg-block">Recommandation</div>
      <div class="d-lg-none">Recommandation</div>
    </a>
  </li>
</ul>
