import {NgModule} from '@angular/core';
import {SidebarMenuComponent} from "./sidebar-menu.component";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {AvatarModule} from "../avatar/avatar.module";
import {StatusCandidateModule} from "../status-candidate/status-candidate.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {OffCanvasNotificationsModule} from "../offCanvas-notifications/offCanvas-notifications.module";
import {SharedModule} from "../../shared.module";
import {AccountCandidateMenuModule} from "../../../account/ui/account-candidate-menu/account-candidate-menu.module";
import {ProfileCandidateMenuModule} from "../../../profile/ui/profile-candidate-menu/profile-candidate-menu.module";
import {ProfileEnterpriseMenuModule} from "../../../profile/ui/profile-enterprise-menu/profile-enterprise-menu.module";
import {AccountEnterpriseMenuModule} from "../../../account/ui/account-enterprise-menu/account-enterprise-menu.module";
import {SearchMenuModule} from "../../../search/ui/search-menu/search-menu.module";

@NgModule({
  imports: [CommonModule, RouterModule, AvatarModule, StatusCandidateModule, FontAwesomeModule, OffCanvasNotificationsModule, SharedModule, AccountCandidateMenuModule, ProfileCandidateMenuModule, ProfileEnterpriseMenuModule, AccountEnterpriseMenuModule, SearchMenuModule],
  declarations: [SidebarMenuComponent],
  exports: [SidebarMenuComponent],
})
export class SidebarMenuModule {}
