import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {AbstractControl, FormGroup} from "@angular/forms";
import {GoogleMapService} from "../../services/GoogleMapService";
import {Subject} from "rxjs";
import {faCircleXmark} from "@fortawesome/pro-regular-svg-icons";
import {faSearch} from "@fortawesome/pro-light-svg-icons";

@Component({
  selector: 'app-form-school',
  templateUrl: './form-school.component.html',
  styleUrls: ['./form-school.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  providers: [GoogleMapService]
})
export class FormSchoolComponent implements OnInit, AfterViewInit {

  fa = {faCircleXmark, faSearch}

  @Input()
  form: FormGroup

  ngUnsubscribe = new Subject<void>();

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  remove() {
    this.school.reset()
  }

  get school(): AbstractControl {
    return this.form.get('school')!;
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['formErrors']) {
  //     for (let error in this.formErrors) {
  //       this.form.get(error)?.setErrors({serverError: this.formErrors[error]}, {emitEvent: true})
  //     }
  //     this.ref.markForCheck()
  //   }
  //
  //   console.log(changes)
  // }
}
