import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";

const API_URL = environment.API_URL;
const PATH = '/pixelSearchMatch/'

@Injectable({
  providedIn: 'root'
})
export class PixelSearchMatchService {

  constructor(private http: HttpClient) {
  }

  history() {
    return this.http.get<any>(API_URL + PATH + 'history')
  }

  bookmark(pixelSearchMatchId: string) {
    return this.http.patch<any>(API_URL + PATH + pixelSearchMatchId + '/bookmark', {})
  }

  delete(pixelSearchMatchId: string) {
    return this.http.delete<any>(API_URL + PATH + pixelSearchMatchId)
  }
}
