import {Injectable, OnDestroy} from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import {Subject, takeUntil} from "rxjs";

function promptUser(event: VersionReadyEvent): boolean {
  return confirm('Nouvelle mise à jour, voulez-vous recharger la page maintenant ?');
}

@Injectable()
export class PromptUpdateService implements OnDestroy {

  ngUnsubscribe = new Subject<void>();

  constructor(swUpdate: SwUpdate) {
    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(evt => {
        document.location.reload();
      });
    const updatesAvailable = swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))).pipe(takeUntil(this.ngUnsubscribe));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
