import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {

  transform(value: number, compare: number): unknown {
    return (value & compare) !== 0
  }
}
