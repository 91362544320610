import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[checked]'
})
export class CheckedDirective implements AfterViewInit, AfterViewChecked {

  constructor(private el: ElementRef, private ref: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    if (this.el.nativeElement) {
      let childrens = this.el.nativeElement.children
      for (let i = 0; i < childrens.length; i++) {
        childrens[i].addEventListener('click', (event: any) => {
          for (let j = 0; j < event.target.children.length; j++) {
            const input = this.findInput(event.target.children[j])

            if (input !== null) {
              this.clearOtherRadio(input, this.el.nativeElement)
              input.click()
              this.ref.markForCheck()
            }
          }
        })
      }
    }
  }

  ngAfterViewChecked(): void {
    if (this.el.nativeElement) {

      let childrens = this.el.nativeElement.children
      for (let i = 0; i < childrens.length; i++) {
        const input = this.findInput(childrens[i])
        if (input !== null) {
          if (input.checked === true) {
            childrens[i].classList.add("checked")
          } else {
            childrens[i].classList.remove("checked")
          }
        }
      }
    }
  }

  clearOtherRadio(target: any, parent: any) {
    if (target.type === 'radio') {
      let childrenParent = parent.children
      for (let i = 0; i < childrenParent.length; i++) {
        for (let j = 0; j < childrenParent[i].children.length; j++) {
          if (childrenParent[i].children[j].localName === 'input' && childrenParent[i].children[j] !== target) {
            childrenParent[i].children[j].checked = false
          }
        }
      }
      this.ref.markForCheck()
    }
  }

  findInput(children: any): any {
    if (children.localName === 'input') {
      return children
    } else {
      if (children.children.length !== 0) {
        for (let i = 0; i < children.children.length; i++) {
          return this.findInput(children.children[i])
        }
      }
    }
    return null
  }
}
