import {createReducer, on} from '@ngrx/store';
import * as PixelSearchMatchActions from '../actions/pixelSearchMatch.actions';
import {PixelSearchMatchModel} from "../models/pixelSearchMatch.model";

export const PixelSearchMatchFeatureKey = 'pixelSearchMatch';

export interface State {
  history: PixelSearchMatchModel[],
  loading: boolean,
  error: any

}

export const initialState: State = {
  history: [],
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(PixelSearchMatchActions.loadHistory, (state) => ({...state, loading: true, error: null})),
  on(PixelSearchMatchActions.loadHistorySuccess, (state, {data}) => ({
    ...state,
    history: data,
    loading: false,
    error: null
  })),
  on(PixelSearchMatchActions.loadHistoryFailure, (state, {error}) => ({...state, loading: false, error})),
);
