import {NgModule} from '@angular/core';
import {StatusCandidateComponent} from "./status-candidate.component";
import {CommonModule} from "@angular/common";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {ModalAvailabilityCandidateModule} from "../modal-availability-candidate/modal-availability-candidate.module";

@NgModule({
  imports: [CommonModule, FontAwesomeModule, ModalAvailabilityCandidateModule],
  declarations: [StatusCandidateComponent],
  exports: [StatusCandidateComponent],
})
export class StatusCandidateModule {}
