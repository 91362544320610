import {createAction, props} from '@ngrx/store';
import {PixelSearchMatchModel} from "../models/pixelSearchMatch.model";

export const loadHistory = createAction(
  '[PixelSearchMatch] Load PixelSearchMatchHistory History'
);

export const loadHistorySuccess = createAction(
  '[PixelSearchMatch] Load PixelSearchMatchHistory Success',
  props<{ data: any }>()
);

export const loadHistoryFailure = createAction(
  '[PixelSearchMatch] Load PixelSearchMatchHistory Failure',
  props<{ error: any }>()
);

export const deletePixelSearchMatch = createAction(
  '[PixelSearchMatch] delete History',
  props<{ id: string }>()
);

export const deletePixelSearchMatchSuccess = createAction(
  '[PixelSearchMatch] delete Success',
  props<{ data: any }>()
);

export const deletePixelSearchMatchFailure = createAction(
  '[PixelSearchMatch] delete Failure',
  props<{ error: any }>()
);

export const bookmarkPixelSearchMatch = createAction(
  '[PixelSearchMatch] bookmark',
  props<{ id: string }>()
);

export const bookmarkPixelSearchMatchSuccess = createAction(
  '[PixelSearchMatch] bookmark Success',
  props<{ data: PixelSearchMatchModel }>()
);

export const bookmarkPixelSearchMatchFailure = createAction(
  '[PixelSearchMatch] bookmark Failure',
  props<{ error: any }>()
);
