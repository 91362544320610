import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaxPipe, MinPipe} from "./pipes/min.pipe";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EnvironmentPipe} from "./pipes/environment.pipe";
import {ReadMoreModule} from "./ui/read-more/read-more.module";
import {FilterPipe} from "./pipes/filter.pipe";
import {CountPipe} from "./pipes/count.pipe";
import {ScrollToBottomDirective} from "./directives/scroll-to-bottom/scroll-to-bottom.directive";
import {CheckedDirective} from "./directives/radio-checked/radio-checked.directive";
import {ScrollToErrorDirective} from "./directives/scroll-to-error/scroll-to-error.directive";
import {ScrollTabsDirective} from "./directives/scroll-tabs/scroll-tabs.directive";
import {TooltipDirective} from "./directives/tooltip/tooltip.directive";
import {SafePipe} from "./pipes/safe.pipe";
import {MaskPipe} from "./pipes/mask.pipe";
import {LoadScriptDirective} from "./directives/loadScript/loadScript.directive";
import {CanSearchPipe} from "./pipes/canSearch.pipe";

@NgModule({
  declarations: SharedModule.COMPONENT_LIST,
  imports: [
    SharedModule.MODULE_LIST,
  ],
  exports: [SharedModule.MODULE_LIST, SharedModule.COMPONENT_LIST]
})
export class SharedModule {
  static readonly MODULE_LIST = [
    // FontAwesomeModule,
    FormsModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ReadMoreModule
  ];
  static readonly COMPONENT_LIST = [
    MinPipe,
    MaxPipe,
    EnvironmentPipe,
    FilterPipe,
    CountPipe,
    SafePipe,
    MaskPipe,
    CanSearchPipe,
    ScrollToBottomDirective,
    CheckedDirective,
    ScrollToErrorDirective,
    ScrollTabsDirective,
    TooltipDirective,
    LoadScriptDirective
  ];
}
