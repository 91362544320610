import {createReducer, on} from '@ngrx/store';
import * as SearchActions from '../actions/search.actions';
import {SearchModel} from "../models/search.model";

export const SearchFeatureKey = 'search';

export interface State {
  result: SearchModel[]
  meta: {last_page: number, total: number}
  per_page: number
  searchInProgress: boolean
  sort: {order: string, target: string}
  filter: string
  error: any
}

export const initialState: State = {
  result: [],
  searchInProgress: false,
  per_page: 50,
  meta: {last_page: 0, total: 0},
  sort: {order: 'desc', target: 'new'},
  filter: 'all',
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(SearchActions.loadSearch, (state) => ({...state, searchInProgress: true, error: null})),
  on(SearchActions.loadSearchSuccess, (state, {data}) => ({
    ...state,
    result: data.meta.current_page === 1 ? [...data.data] : [...state.result, ...data.data],
    meta: data.meta,
    searchInProgress: false,
    error: null
  })),
  on(SearchActions.loadSearchFailure, (state, {error}) => ({...state, searchInProgress: false, error})),
  on(SearchActions.updateSearchSortAndFilter, (state, {filter, sort}) => ({
    ...state,
    sort: {...sort},
    filter: filter,
  })),
  on(SearchActions.resetSearch, (state, {}) => ({
    ...state,
    result: [],
    meta: {last_page: 0, total: 0}
  })),
  on(SearchActions.updateSearchCProfileFavorite, (state, {cProfile}) => ({
    ...state,
    result: state.result.map(item =>
      item.id === cProfile.id ? {...item, is_favorite: !item.is_favorite} : item
    ),
  })),
);
