import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import {Observable} from "rxjs";
import {faCircleXmark} from "@fortawesome/pro-regular-svg-icons";
import {faSearch} from "@fortawesome/pro-light-svg-icons";
import {DepartmentsStore} from "../../data-access/departments.store";

@Component({
  selector: 'app-form-jobs',
  templateUrl: './form-jobs.component.html',
  styleUrls: ['./form-jobs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  providers: [DepartmentsStore,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormJobsComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: FormJobsComponent,
    },
  ],
})
export class FormJobsComponent implements OnInit, AfterViewInit, ControlValueAccessor, Validator {

  fa = {faCircleXmark, faSearch}

  @Input()
  formControl!: FormControl;

  @ViewChild('tmpSelectedJob')
  tmpSelectedJob: ElementRef

  @Input()
  displayPreview: boolean = true

  departments$: Observable<any> = this.departmentsStore$.state$

  constructor(private ref: ChangeDetectorRef, private departmentsStore$: DepartmentsStore) {
    this.departmentsStore$.getWithJobs()
  }

  ngOnInit(): void {
    // // @ts-ignore
    // if (this.formControl['_markAsTouched'] === undefined) {
    // }
    // @ts-ignore
    this.formControl['_markAsTouched'] = this.formControl.markAsTouched;
    this.formControl.markAsTouched = () => {
      // @ts-ignore
      this.formControl['_markAsTouched']();
      // this.formControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.ref.markForCheck()
    }
    this.filterJobs()
  }

  ngAfterViewInit(): void {
  }

  public onTouched: () => void = () => {};

  public writeValue(v: any) {
    this.ref.markForCheck()
  }

  public setDisabledState(disabled: boolean) {
    disabled ? this.formControl.disable() : this.formControl.enable();
  }

  registerOnChange(fn: any): void {
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  public validate(value: any): ValidationErrors | null {

    // if (this.isFavorite === false) {
    //   return null
    // }
    // if (value.value.length !== 0 && this.isFavorite) {
    //   return null
    // }

    this.ref.markForCheck()
    return value.errors;
  }
  addJob(job: any) {
    if (job) {
      let jobs: any[] = this.formControl.value
      if (!jobs) {
        jobs = []
      }

      this.formControl.patchValue([...jobs, job])
      if (this.tmpSelectedJob) {
        // @ts-ignore
        this.tmpSelectedJob.clearItem(job)
      }
      this.departmentsStore$.remove(job.id)
    }
  }

  removeJob(job: any) {
    if (job) {
      let jobs: any[] = [...this.formControl.value]

      let index = jobs.findIndex((jobIndex: any) => jobIndex.id == job.id)
      if (index !== -1) {
        jobs.splice(index, 1)
      }
      this.departmentsStore$.add(job)
      this.formControl.patchValue(jobs)
    }
  }

  filterJobs() {
    this.formControl.value.forEach((job: any) => {
      this.departmentsStore$.add(job)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ref.markForCheck()
  }
}
