import {createReducer, on} from '@ngrx/store';
import * as JobOfferActions from '../actions/jobOffer.actions';
import {JobOfferModel} from "../models/jobOffer.model";

export const SearchFeatureKey = 'jobOffer';

export interface State {
  current: JobOfferModel | null
  jobOffers: JobOfferModel[]
  loading: boolean
  error: any
}

export const initialState: State = {
  current: null,
  jobOffers: [],
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(JobOfferActions.getJobOffers, (state) => ({...state, loading: true, error: null})),
  on(JobOfferActions.getJobOffersSuccess, (state, {data}) => ({
    ...state,
    jobOffers: [...data],
    loading: false,
    error: null
  })),
  on(JobOfferActions.getJobOffersFailure, (state, {error}) => ({...state, loading: false, error})),
);
