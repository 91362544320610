import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {faCaretDown, faCircle} from "@fortawesome/pro-solid-svg-icons";
import {SubscriberService} from "../../data-access/subscriber.service";
import {GetSubscriber, SubscriberStateModel} from "../../models/Subscriber";
import {Store} from "@ngxs/store";
import {SubscriberState} from "../../../state/subscriber-state.service";
import {Subject, takeUntil} from "rxjs";
import {
  ModalAvailabilityCandidateComponent
} from "../modal-availability-candidate/modal-availability-candidate.component";

@Component({
  selector: 'app-status-candidate',
  templateUrl: './status-candidate.component.html',
  styleUrls: ['./status-candidate.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusCandidateComponent implements OnInit, OnDestroy {

  fa = {faCircle, faCaretDown}
  @Input()
  subscriber: any = null

  @ViewChild('modalAvailabilityCandidateComponent')
  modalAvailabilityCandidate: ModalAvailabilityCandidateComponent

  ngUnsubscribe = new Subject<void>();

  constructor(private subscriberService: SubscriberService, private store: Store, private ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.store.select(SubscriberState).pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscriber: SubscriberStateModel) => {
      this.subscriber = subscriber
      this.ref.markForCheck()
    })
  }

  available() {
    this.subscriberService.available().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.store.dispatch(new GetSubscriber())
    })
  }

  standby() {
    this.subscriberService.standby().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.store.dispatch(new GetSubscriber())
    })
  }

  unavailable() {
    this.modalAvailabilityCandidate.show()
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
