import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromCommonReducer from '../reducers/common.reducer';

export const getCommonState = createFeatureSelector<fromCommonReducer.State>('common');

export const loading = createSelector(getCommonState, (state: fromCommonReducer.State) => state.loading);

export const selectError = createSelector(getCommonState, (state: fromCommonReducer.State) => state.error);

export const selectSkills = createSelector(getCommonState, (state: fromCommonReducer.State) => state.skills);
