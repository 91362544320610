import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";

const API_URL = environment.API_URL;
const PATH = '/search/'

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) {
  }

  search(body: any) {
    return this.http.post<any>(API_URL + PATH, body)
  }
}
