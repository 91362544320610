import {createReducer, on} from '@ngrx/store';
import * as SubscriberSearchActions from '../actions/subscriberSearch.actions';
import {SubscriberSearchModel} from "../models/subscriberSearch.model";

export const SearchFeatureKey = 'subscriberSearch';

export interface State {
  all: SubscriberSearchModel[]
  current: SubscriberSearchModel | null
  loading: boolean
  error: any
}

export const initialState: State = {
  all: [],
  current: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(SubscriberSearchActions.loadSearches, (state) => ({...state, loading: true, error: null})),
  on(SubscriberSearchActions.loadSearchesSuccess, (state, {subscriberSearches}) => ({
    ...state,
    all: subscriberSearches,
    loading: false,
    error: null
  })),
  on(SubscriberSearchActions.loadSearchesFailure, (state, {error}) => ({...state, loading: false, error})),
  on(SubscriberSearchActions.setCurrentSubscriberSearch, (state, {subscriberSearch}) => ({
    ...state,
    current: subscriberSearch,
  })),
  on(SubscriberSearchActions.storeSubscriberSearch, (state) => ({...state, loading: true, error: null})),
  on(SubscriberSearchActions.storeSubscriberSearchSuccess, (state, {subscriberSearch}) => ({
    ...state,
    current: subscriberSearch,
    loading: false,
    error: null
  })),
  on(SubscriberSearchActions.storeSubscriberSearchFailure, (state, {error}) => ({...state, loading: false, error})),
  on(SubscriberSearchActions.updateSubscriberSearch, (state) => ({...state, loading: true, error: null})),
  on(SubscriberSearchActions.updateSubscriberSearchSuccess, (state, {subscriberSearch}) => ({
    ...state,
    current: subscriberSearch,
    loading: false,
    error: null
  })),
  on(SubscriberSearchActions.updateSubscriberSearchFailure, (state, {error}) => ({...state, loading: false, error})),
);
