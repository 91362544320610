import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

const API_URL = environment.API_URL;
const PATH = '/subscriber/'

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  constructor(private http: HttpClient) { }

  get(): Observable<any> {
    return this.http.get<any>(API_URL + PATH + 'me')
  }

  available(): Observable<any> {
    return this.http.patch<any>(API_URL + PATH + 'available', {})
  }

  standby(): Observable<any> {
    return this.http.patch<any>(API_URL + PATH + 'standby', {})
  }

  unavailable(reason: string, comment: string|null): Observable<any> {
    return this.http.patch<any>(API_URL + PATH + 'unavailable', {reason, comment})
  }
}
