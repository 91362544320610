<div class="modal modal-lg fade" id="exampleModal" tabindex="-1" data-bs-backdrop="true" aria-labelledby="exampleModalLabel" aria-hidden="true" #modal>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header p-4">
        <h2 *ngIf="!id.value" class="mb-0">Enregistrer la recherche</h2>
        <h2 *ngIf="id.value" class="mb-0">Modifier la recherche</h2>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-4">

        <form [formGroup]="form" (submit)="submit()">
          <div class="row">
            <div class="col-sm-12">
              <label class="col-form-label required">Titre</label>
              <input type="text" class="form-control" formControlName="name" placeholder="Développeur Back-End"
                     [ngClass]="{'is-invalid' : (name.dirty || name.touched) && name.errors}">

              <div *ngIf="name?.invalid && (name?.dirty || name?.touched)" class="invalid-feedback">
                <div *ngIf="name?.hasError('required')">
                  Ce champ est obligatoire
                </div>
                <div *ngIf="name?.errors?.['serverError']">
                  {{name.errors?.['serverError']}}
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="mt-4 d-flex justify-content-between align-items-center mt-3 flex-wrap">
          <div>
            <a class="text-muted pointer fw-bold-600" *ngIf="id.value" (click)="delete()">Supprimer</a>
          </div>
          <div class="d-flex justify-content-end align-items-center gap-3">
            <a class="text-muted pointer fw-bold-600" (click)="hide()">Annuler</a>
            <app-loading-button [loading]="loading$ | async" [class]="'btn btn-primary'" [type]="'submit'" [click]="submit" [disabled]="!form.valid">
              Enregistrer
            </app-loading-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
