<div class="input-group flex-nowrap search">
  <span class="input-group-text"><fa-icon [icon]="fa.faSearch"></fa-icon></span>
  <input class="form-control" type="text" (keyup.enter)="addContent(content.value)" #content [placeholder]="placeholder">
</div>

<div class="d-flex mt-3 flex-wrap gap-2" *ngIf="displayPreview && formControl.value?.length !== 0">
  <div *ngFor="let content of formControl.value" class="tag tag-secondary selected pointer" (click)="removeContent(content)">
    {{content}} <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
  </div>
</div>
