import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as EProfileActions from '../actions/eProfile.actions';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {of, tap} from 'rxjs';
import {Store} from "@ngrx/store";
import {EProfileService} from "../apis/eProfile.service";
import {getQuota, updateBusinessSelected} from "../actions/eProfile.actions";
import {PaymentService} from "../apis/payment.service";
import {selectBusiness, selectMe} from "../selectors/eProfile.selectors";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class EProfileEffects {
  constructor(private actions$: Actions, private eProfileService: EProfileService,
              private paymentService: PaymentService, private store: Store, private toast: ToastrService) {
  }

  loadMe$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EProfileActions.loadMe),
        map((action: any) => action),
        mergeMap(() => {
          return this.eProfileService.me().pipe(
            map(data => EProfileActions.loadMeSuccess({data})),
            catchError(error => of(EProfileActions.loadMeFailure({error})))
          );
        })
      )
    }
  );

  loadMeSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EProfileActions.loadMeSuccess),
      withLatestFrom(this.store.select(selectBusiness)),
      mergeMap(([action, currentState]) => {
        if (action.data.business != 3) {
          this.store.dispatch(updateBusinessSelected({business: action.data.business}))
        } else {
          this.store.dispatch(updateBusinessSelected({business: currentState != 0 ? currentState : 1}))
        }
        return of({ type: 'NO_ACTION' });
      })
    );
  });

  signUp$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EProfileActions.updateSignUp),
        map((action: any) => action),
        mergeMap(({body, step}) => {
          return this.eProfileService.signUp(body, step).pipe(
            map(data => EProfileActions.updateSignUpSuccess({data})),
            catchError(error => of(EProfileActions.updateSignUpFailure({error})))
          );
        })
      )
    }
  );

  quota$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EProfileActions.getQuota),
        map((action: any) => action),
        mergeMap(() => {
          return this.eProfileService.quota().pipe(
            map(data => EProfileActions.getQuotaSuccess({data})),
            catchError(error => of(EProfileActions.getQuotaFailure({error})))
          );
        })
      )
    }
  );

  initPaymentMethod$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EProfileActions.initPaymentMethod),
        map((action: any) => action),
        mergeMap(() => {
          return this.paymentService.index().pipe(
            map(data => EProfileActions.initPaymentMethodSuccess({data})),
            catchError(error => of(EProfileActions.initPaymentMethodFailure({error})))
          );
        })
      )
    }
  );

  updatePaymentMethod$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EProfileActions.updatePaymentMethod),
        map((action: any) => action),
        mergeMap(({pmId}) => {
          return this.paymentService.update(pmId).pipe(
            map(data => EProfileActions.updatePaymentMethodSuccess({data})),
            catchError(error => of(EProfileActions.updatePaymentMethodFailure({error})))
          );
        })
      )
    }
  );

  invoices$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EProfileActions.getInvoices),
        map((action: any) => action),
        mergeMap(() => {
          return this.paymentService.invoices().pipe(
            map(data => EProfileActions.getInvoicesSuccess({data})),
            catchError(error => of(EProfileActions.getInvoicesFailure({error})))
          );
        })
      )
    }
  );

  updateBillingDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EProfileActions.updateBillingDetails),
      withLatestFrom(this.store.select(selectMe)),
      mergeMap(([action, currentState]) => {
        const { name, address, zip_code, city, country, tva } = action;
        return this.paymentService.updateBillingDetails(name, address, zip_code, city, country, tva).pipe(
          map(data => {
            const updatedMe = {...currentState, billing_details: {...currentState?.billing_details, ...data}};
            return EProfileActions.updateBillingDetailsSuccess({data: updatedMe});
          }),
          catchError(error => of(EProfileActions.updateBillingDetailsFailure({error})))
        );
      })
    );
  });

  sendNotificationForShortList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EProfileActions.sendNotificationForShortList),
      withLatestFrom(this.store.select(selectMe)),
      mergeMap(([action, currentState]) => {
        return this.eProfileService.sendNotificationForShortList(currentState?.enterprise ?? '').pipe(
          map(data => {
            this.toast.success('Un Account Manager va vous contacter prochainement', 'Confirmation')
            return EProfileActions.sendNotificationForShortListSuccess()
          }),
          catchError(error => of(EProfileActions.sendNotificationForShortListFailure({error})))
        );
      })
    );
  });

  sendNotificationForContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EProfileActions.sendNotificationForContact),
      withLatestFrom(this.store.select(selectMe)),
      mergeMap(([action, currentState]) => {
        return this.eProfileService.sendNotificationForContact(currentState?.enterprise ?? '').pipe(
          map(data => EProfileActions.sendNotificationForContactSuccess()),
          catchError(error => of(EProfileActions.sendNotificationForContactFailure({error})))
        );
      })
    );
  });

  pushes$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EProfileActions.getPushes),
        map((action: any) => action),
        mergeMap(() => {
          return this.eProfileService.getPushes().pipe(
            map(data => EProfileActions.getPushesSuccess({data})),
            catchError(error => of(EProfileActions.getPushesFailure({error})))
          );
        })
      )
    }
  );

  //TODO: a supprimer
  updatedBusiness$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EProfileActions.updateBusiness),
        map((action: any) => action),
        mergeMap(({business}) => {
          return this.eProfileService.updateBusiness(business).pipe(
            map(data => EProfileActions.loadMeSuccess({data})),
            catchError(error => of(EProfileActions.updateBusinessFailure({error})))
          );
        })
      )
    }
  );

  updatedBusinessSelected$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EProfileActions.updateBusinessSelected),
        tap(({business}) => {
          if (business === 2) {
            this.store.dispatch(getQuota())
          }
        }),
      );
    },
    {dispatch: false}
  );

  addDay$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EProfileActions.addDay),
        map((action: any) => action),
        mergeMap(() => {
          return this.eProfileService.addDay().pipe(
            map(data => EProfileActions.addDaySuccess({data})),
            catchError(error => of(EProfileActions.addDayFailure({error})))
          );
        })
      )
    }
  );

  addDaySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EProfileActions.addDaySuccess),
        tap(() => {
          window.location.reload();
        }),
      );
    },
    {dispatch: false}
  );
}
