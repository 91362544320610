import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {faCircleCheck, faCircleInfo, faCircleXmark, faXmark} from '@fortawesome/pro-light-svg-icons';
import {Toast} from "ngx-toastr";

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent extends Toast implements OnInit {

  fa = {faXmark, faCircleCheck, faCircleXmark, faCircleInfo}
  ngOnInit() {
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
