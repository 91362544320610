import {AfterViewChecked, ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {faUser, faPuzzlePiece, faSquareExclamation, faBell, faPenNib, faCircleQuestion, faRightFromBracket} from "@fortawesome/pro-light-svg-icons";
import {Router} from "@angular/router";

@Component({
  selector: 'app-account-enterprise-menu',
  templateUrl: './account-enterprise-menu.component.html',
  styleUrls: ['./account-enterprise-menu.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountEnterpriseMenuComponent implements OnInit, AfterViewChecked {

  fa = {faUser, faPuzzlePiece, faSquareExclamation, faBell, faPenNib, faCircleQuestion, faRightFromBracket}

  @Input()
  subscriber: any = null

  toolsVisited: boolean = true

  @Input()
  offcanvas: boolean = false

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    if (localStorage.getItem('toolsVisited') !== 'true') {
      this.toolsVisited = false
    } else {
      this.toolsVisited = true
    }
  }
}
