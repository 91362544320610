<ul class="nav nav-pills nav-flush d-flex flex-column gap-2 mt-4">
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/profile/job-offer" [ariaCurrentWhenActive]="'page'" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'"
       [routerLinkActive]="'active'">
      <fa-icon [icon]="fa.faListDropdown"></fa-icon>
      <div class="d-flex justify-content-between flex-fill align-items-center">
        <div class="d-none d-lg-block">Postes à pourvoir</div>
        <div class="d-lg-none">Postes à pourvoir</div>
        <div class="badge" *ngIf="jofOfferEmpty">&nbsp;</div>
      </div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/profile/info" [routerLinkActive]="'active'" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'">
      <fa-icon [icon]="fa.faInfoSquare"></fa-icon>
      <div class="d-flex justify-content-between flex-fill align-items-center">
        <div class="d-none d-lg-block">Informations entreprise</div>
        <div class="d-lg-none">Informations entreprise</div>
        <!--        <div class="badge" *ngIf="jofOfferEmpty">&nbsp;</div>-->
      </div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/profile/collaborators" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'"
       [routerLinkActive]="'active'">
      <fa-icon [icon]="fa.faUserTie"></fa-icon>
      <div class="d-flex justify-content-between flex-fill align-items-center">
        <div class="d-none d-lg-block">Collaborateurs</div>
        <div class="d-lg-none">Collaborateurs</div>
        <div class="badge" *ngIf="collaboratorsVisited">&nbsp;</div>
      </div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/profile/branding" [routerLinkActive]="'active'" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'">
      <fa-icon [icon]="fa.faBuildingUser"></fa-icon>
      <div class="d-flex justify-content-between flex-fill align-items-center">
        <div class="d-none d-lg-block">Marque employeur</div>
        <div class="d-lg-none">Marque employeur</div>
        <div class="badge" *ngIf="brandEmpty">&nbsp;</div>
      </div>
    </a>
  </li>
  <ng-container *ngIf="eProfile$ | async as eProfile">
    <li class="nav-item flex-fill" *ngIf="eProfile.business | mask : 2">
      <a class="nav-link d-flex gap-2" routerLink="/profile/plan" [routerLinkActive]="'active'" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'">
        <fa-icon [icon]="fa.faTags"></fa-icon>
        <div class="d-flex justify-content-between flex-fill align-items-center">
          <div class="d-none d-lg-block">Plan & facturation</div>
          <div class="d-lg-none">Plan & facturation</div>
          <div class="badge" *ngIf="planBillingVisited">&nbsp;</div>
        </div>
      </a>
    </li>
  </ng-container>
</ul>
