import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[appScrollToBottom]'
})
export class ScrollToBottomDirective implements AfterViewChecked {

  constructor(private self: ElementRef, private ref: ChangeDetectorRef) {}

  private scrollToBottom() {
    this.self.nativeElement.scrollTop = this.self.nativeElement.scrollHeight;
    this.ref.markForCheck()
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom()
  }
}
