import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as CProfileActions from '../actions/cProfile.actions';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from "@ngrx/store";
import {CProfileService} from "../apis/cProfile.service";
import {ToastrService} from "ngx-toastr";
import * as SearchSelectors from "../selectors/search.selectors";
import * as SearchActions from "../actions/search.actions";
import * as CProfileSelectors from "../selectors/cProfile.selectors";
import * as EProfileActions from "../actions/eProfile.actions";
import * as EProfileSelectors from "../selectors/eProfile.selectors";
import * as PropositionSelectors from "../selectors/proposition.selectors";
import * as PropositionActions from "../actions/proposition.actions";
import {PropositionModel} from "../models/proposition.model";
import {PushModel} from "../models/push.model";

@Injectable()
export class CProfileEffects {
  constructor(private actions$: Actions, private cProfileService: CProfileService, private store: Store,
              private toast: ToastrService) {
  }

  loadMe$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.loadMe),
        map((action: any) => action),
        mergeMap(() => {
          return this.cProfileService.me().pipe(
            map(data => CProfileActions.loadMeSuccess({data})),
            catchError(error => of(CProfileActions.loadMeFailure({error})))
          );
        })
      )
    }
  );

  getCProfile$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.getCProfile),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.cProfileService.get(id).pipe(
            map(data => CProfileActions.getCProfileSuccess({data})),
            catchError(error => of(CProfileActions.getCProfileFailure({error})))
          );
        })
      )
    }
  );


  getCProfileError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CProfileActions.getCProfileFailure),
        mergeMap(({error}) => {
          this.toast.error('Vous avez dépassé votre limite quotidienne de consultations de profils', 'Limite Atteinte')
          return of({ type: 'NO_ACTION' });
        })
      );
    },
    {dispatch: false}
  );

  updateFavorite$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.updateCProfileFavorite),
        map((action: any) => action),
        mergeMap(({cProfileId}) => {
          return this.cProfileService.updateFavorite(cProfileId).pipe(
            map(data => {
              if (data.is_favorite) {
                this.toast.success('Profil ajouté aux favoris', 'Confirmation')
              } else {
                this.toast.success('Profil supprimé des favoris', 'Confirmation')
              }

              return CProfileActions.updateCProfileFavoriteSuccess({data})
            }),
            catchError(error => of(CProfileActions.updateCProfileFavoriteFailure({error})))
          );
        })
      )
    }
  );

  updateFavoriteSearchSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CProfileActions.updateCProfileFavoriteSuccess),
        withLatestFrom(this.store.select(SearchSelectors.selectResult)),
        mergeMap(([action, currentState]) => {

          if (currentState && currentState.length) {
            this.store.dispatch(SearchActions.updateSearchCProfileFavorite({cProfile: action.data}))
          }
          return of({ type: 'NO_ACTION' });
        })
      );
    },
    {dispatch: false}
  );

  updateFavoritePropositionSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CProfileActions.updateCProfileFavoriteSuccess),
        withLatestFrom(this.store.select(PropositionSelectors.selectCurrent)),
        mergeMap(([action, currentState]) => {

          if (currentState) {
            let proposition: PropositionModel = {
              ...currentState, c_profile: {...currentState.c_profile, is_favorite: action.data.is_favorite}
            };
            this.store.dispatch(PropositionActions.getPropositionSuccess({proposition: proposition}))
          }
          return of({ type: 'NO_ACTION' });
        })
      );
    },
    {dispatch: false}
  );

  updateFavoriteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CProfileActions.updateCProfileFavoriteSuccess),
        withLatestFrom(this.store.select(CProfileSelectors.selectCurrent)),
        mergeMap(([action, currentState]) => {

          if (currentState) {
            this.store.dispatch(CProfileActions.getCProfileSuccess({data: action.data}))
          }
          return of({ type: 'NO_ACTION' });
        })
      );
    },
    {dispatch: false}
  );

  updateRejected$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.updateCProfileRejected),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.cProfileService.updateRejected(id).pipe(
            map(data => {
              this.toast.success('Profil supprimé de la shortlist', 'Confirmation')
              return CProfileActions.updateCProfileRejectedSuccess({data})
            }),
            catchError(error => of(CProfileActions.updateCProfileRejectedFailure({error})))
          );
        })
      )
    }
  );

  updateFavoritePushSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CProfileActions.updateCProfileFavoriteSuccess),
        withLatestFrom(this.store.select(EProfileSelectors.selectPushes)),
        mergeMap(([action, currentState]) => {
          if (currentState) {
            let pushes: PushModel[] = currentState.map(push => {
              return {
                ...push,
                c_profiles: push.c_profiles.map(cProfile =>
                  cProfile.id === action.data.id ? {...cProfile, is_favorite: action.data.is_favorite} : cProfile
                )
              };
            })
            this.store.dispatch(EProfileActions.getPushesSuccess({data: pushes}))
          }
          return of({ type: 'NO_ACTION' });
        })
      );
    },
    {dispatch: false}
  );

  getCProfileGuest$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.getCProfileGuest),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.cProfileService.getGuest(id).pipe(
            map(cProfile => CProfileActions.getCProfileGuestSuccess({cProfile})),
            catchError(error => of(CProfileActions.getCProfileGuestFailure({error})))
          );
        })
      )
    }
  );
}
