import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {of, tap} from 'rxjs';
import {select, Store} from "@ngrx/store";
import {SubscriptionService} from "../apis/subscription.service";
import * as SubscriptionSelectors from '../selectors/subscription.selectors';
import * as EProfileActions from '../actions/eProfile.actions';
import * as SubscriptionActions from '../actions/subscription.actions';


@Injectable()
export class SubscriptionEffects {
  constructor(private actions$: Actions, private subscriptionService: SubscriptionService, private store: Store) {
  }

  subscription$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.getSubscription),
        map((action: any) => action),
        mergeMap(() => {
          return this.subscriptionService.get().pipe(
            map((data) => SubscriptionActions.getSubscriptionSuccess({data: !Array.isArray(data) ? data : null})),
            catchError(error => of(SubscriptionActions.getSubscriptionFailure({error})))
          );
        })
      )
    }
  );

  updateSubscription$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.updateSubscription),
        map((action: any) => action),
        mergeMap(({priceId}) => {
          return this.subscriptionService.update(priceId).pipe(
            map((data) => SubscriptionActions.updateSubscriptionSuccess({data: !Array.isArray(data) ? data : null})),
            catchError(error => of(SubscriptionActions.updateSubscriptionFailure({error})))
          );
        })
      )
    }
  );

  updateSubscriptionSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.updateSubscriptionSuccess),
        tap(({data}) => {
          this.store.dispatch(EProfileActions.getQuota())
        }),
      );
    },
    {dispatch: false}
  );


  updateSubscriptionCancel$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.updateSubscriptionCancel),
        map((action: any) => action),
        mergeMap(() => {
          return this.subscriptionService.cancel().pipe(
            map((data) => SubscriptionActions.updateSubscriptionCancelSuccess({data: !Array.isArray(data) ? data : null})),
            catchError(error => of(SubscriptionActions.updateSubscriptionCancelFailure({error})))
          );
        })
      )
    }
  );

  prices$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.getSubscriptionPrices),
        withLatestFrom(this.store.pipe(select(SubscriptionSelectors.selectPrices))),
        mergeMap(([action, prices]) => {
          if (prices && prices.length > 0) {
            return of({type: 'NO_ACTION'});
          } else {
            return this.subscriptionService.prices().pipe(
              map(data => SubscriptionActions.getSubscriptionPricesSuccess({data})),
              catchError(error => of(SubscriptionActions.getSubscriptionPricesFailure({error})))
            );
          }
        })
      )
    }
  );
}
