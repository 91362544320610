import {ChangeDetectorRef, Injectable} from "@angular/core";
import {Store} from "./store";
import {TemplateService} from "./template.service";

@Injectable({
  providedIn: 'root'
})
export class TemplatesStore extends Store<[]|null> {
  constructor (private ref: ChangeDetectorRef, private templateService: TemplateService) {
    super(null);
    this.get()
  }

  get(): void {
    this.templateService.getAll().subscribe(data => {
      this.setState(data);
      this.ref.markForCheck()
    });
  }

  delete(id: string): void {
    this.templateService.delete(id).subscribe(data => {
      this.get()
    });
  }
}
