import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {PropositionModel} from "../models/proposition.model";

const API_URL = environment.API_URL;
const PATH = '/proposition/'

@Injectable({
  providedIn: 'root'
})
export class PropositionService {

  constructor(private http: HttpClient) {
  }

  load() {
    return this.http.get<PropositionModel[]>(API_URL + PATH )
  }

  get(id: number ) {
    return this.http.get<PropositionModel>(API_URL + PATH + id)
  }

  getWithMessages(id: number ) {
    return this.http.get<PropositionModel>(API_URL + PATH + id + '/messages')
  }

  create(cProfileId: string, JobOfferId: string, message: string, salary_min: number, salary_max: number ) {
    return this.http.post<PropositionModel>(API_URL + PATH, {c_profile_id: cProfileId, job_offer_id: JobOfferId, message, salary_min, salary_max})
  }

  message(id: number, message: string) {
    return this.http.post<PropositionModel>(API_URL + PATH + id + '/message', {message})
  }

  accept(proposition: PropositionModel) {
    return this.http.post<PropositionModel>(API_URL + PATH + proposition.id + '/accept', {})
  }

  refuse(proposition: PropositionModel, reason: number, other_reason: string) {
    return this.http.post<PropositionModel>(API_URL + PATH + proposition.id + '/refuse', {reason: reason, other_reason: other_reason})
  }
}
