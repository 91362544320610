import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, HostListener,
  OnDestroy, OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {SubscriberState} from "../../../state/subscriber-state.service";
import {Store} from "@ngxs/store";
import {Store as StoreRx} from "@ngrx/store";
import {SubscriberStateModel} from "../../models/Subscriber";
import {NavigationEnd, Router} from "@angular/router";
import {BehaviorSubject, filter, Observable, Subject, takeUntil} from "rxjs";
import {
  faBars,
  faBell,
  faBriefcase,
  faCircleQuestion,
  faCommentLines,
  faComments,
  faFileMagnifyingGlass,
  faGift,
  faHandshakeSimple,
  faHouse,
  faAddressCard,
  faLightbulbOn,
  faPaperclip,
  faPuzzlePiece,
  faSearch,
  faUsersViewfinder
} from "@fortawesome/pro-light-svg-icons";
import {OffCanvasNotificationsComponent} from "../offCanvas-notifications/offCanvas-notifications.component";
import {NotificationsStore} from "../../data-access/notifications.store";
import {faCaretRight} from '@fortawesome/pro-solid-svg-icons';
import {selectMe} from "../../../core/selectors/eProfile.selectors";
import {addDay, updateBusiness} from "../../../core/actions/eProfile.actions";
import * as EProfileSelectors from "../../../core/selectors/eProfile.selectors";
import {Store as StoreNgrx} from "@ngrx/store";

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  providers: [NotificationsStore]
})
export class SidebarMenuComponent implements OnDestroy, OnInit {

  fa = {
    faSearch,
    faComments,
    faAddressCard,
    faBell,
    faHouse,
    faCircleQuestion,
    faGift,
    faFileMagnifyingGlass,
    faLightbulbOn,
    faBriefcase,
    faCommentLines,
    faPaperclip,
    faHandshakeSimple,
    faCaretRight,
    faBars,
    faPuzzlePiece,
    faUsersViewfinder
  }

  subscriber$: Observable<SubscriberStateModel> = this.store.select(SubscriberState)
  business$ = this.storeNgrx.select(EProfileSelectors.selectBusiness)

  displayAccountMenu: boolean = false
  displayProfileMenu: boolean = false
  displaySearchMenu: boolean = false

  notificationDisplay$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  @ViewChild('menu')
  menu: TemplateRef<any>

  @ViewChild('header')
  header: TemplateRef<any>;

  @ViewChild('notificationElement')
  notificationElement: ElementRef

  @ViewChild('offCanvasNotificationsComponent')
  offCanvasNotificationsComponent: OffCanvasNotificationsComponent

  profileNotFilled: boolean = false
  accountNotFilled: boolean = false

  notifications$: Observable<any> = this.notificationsStore$.state$
  interval: any;

  eProfileMe$ = this.storeRx.select(selectMe)

  ngUnsubscribe = new Subject<void>();

  isSmallScreen = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth < 992;
  }

  ngOnInit() {
    this.onResize(null);
  }

  constructor(private store: Store, private storeRx: StoreRx, private router: Router, private ref: ChangeDetectorRef,
              private notificationsStore$: NotificationsStore, private storeNgrx: StoreNgrx) {

    this.displaySubMenu(this.router.url)

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd), takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.displaySubMenu(data.url)
    });

    this.notificationDisplay$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      if (this.notificationElement) {
        if (value) {
          this.notificationElement.nativeElement.classList.add("active")
        } else {
          this.notificationElement.nativeElement.classList.remove("active")
        }
      }
    })

    this.subscriber$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {
      if (val.id) {
        if (val.profile_type === 'E') {
          if (localStorage.getItem('jofOfferNotEmpty') !== 'true' || localStorage.getItem('collaboratorsVisited') !== 'true'
            || localStorage.getItem('brandNotEmpty') !== 'true') {
            this.profileNotFilled = true
          } else {
            this.profileNotFilled = false
          }
          if (localStorage.getItem('toolsVisited') !== 'true') {
            this.accountNotFilled = true
          } else {
            this.accountNotFilled = false
          }
        }
        this.notificationsStore$.get()
        this.interval = setInterval(() => this.notificationsStore$.get(), 60000 * 5)
      }
    })
  }

  read = (notification: any) => {
    this.notificationsStore$.read(notification.id)
  }

  readAll = () => {
    this.notificationsStore$.readAll()
  }

  private displaySubMenu(url: string) {
    this.displayProfileMenu = false
    this.displayAccountMenu = false
    this.displaySearchMenu = false
    if (url.indexOf('/account') !== -1) {
      this.displayAccountMenu = true
    } else if (url.indexOf('/profile') !== -1) {
      this.displayProfileMenu = true
    } else if (url.indexOf('/search') !== -1) {
      this.displaySearchMenu = true
    }

    this.ref.markForCheck()
  }

  updateBusiness(business: number) {
    this.storeRx.dispatch(updateBusiness({business: business}))
  }

  addDay() {
    this.storeRx.dispatch(addDay())
  }

  ngOnDestroy(): void {
    clearInterval(this.interval)
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
