import {Directive, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';

declare var bootstrap: any

@Directive({
  selector: '[Tooltip]'
})
export class TooltipDirective implements OnInit, OnDestroy {

  @Input('Tooltip')
  Tooltip: string | null = '';

  @Input()
  placement = 'top';

  constructor(private el: ElementRef) {
  }

  tooltipLoaded: any = null

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltipLoaded) {
      if (this.tooltipLoaded) { this.tooltipLoaded.hide(); }
    }
  }
  ngOnInit(): void {
    if (this.Tooltip) {
      this.tooltipLoaded = new bootstrap.Tooltip(this.el.nativeElement, {
        title: this.Tooltip,
        html: true,
        placement: this.placement,
        // trigger: 'click'
      })
    }
  }

  ngOnDestroy(): void {
    if (this.tooltipLoaded) {
      if (this.tooltipLoaded) { this.tooltipLoaded.hide(); }
    }
  }
}
