<form [formGroup]="form">
  <ng-container *ngIf="checkbox === false">
    <div class="d-flex gap-3 flex-wrap" checked>
      <div class="form-check form-check-inline radio flex-even">
        <input class="form-check-input" type="radio" id="inlineRadio1" [value]="0" formControlName="remote">
        <label class="form-check-label" for="inlineRadio1">Au bureau</label>
      </div>
      <div class="form-check form-check-inline radio flex-even">
        <input class="form-check-input" type="radio" id="inlineRadio2" [value]="1" formControlName="remote">
        <label class="form-check-label" for="inlineRadio2">Full remote</label>
      </div>
      <div class="form-check form-check-inline radio flex-even">
        <input class="form-check-input" type="radio" id="inlineRadio3" [value]="2" formControlName="remote">
        <label class="form-check-label" for="inlineRadio3">Hybride</label>
      </div>
      <div class="form-check form-check-inline radio flex-even">
        <input class="form-check-input" type="radio" id="inlineRadio4" [value]="3" formControlName="remote">
        <label class="form-check-label" for="inlineRadio4">Aucune préférence</label>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="checkbox === true">
    <div class="d-flex flex-column gap-2" checked>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="inlineRadio1" [value]="0" (change)="onCheckboxChange($event, remote)" [checked]="isSelected(0, remote)">
        <label class="form-check-label" for="inlineRadio1">Au bureau</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="inlineRadio2" [value]="1" (change)="onCheckboxChange($event, remote)" [checked]="isSelected(1, remote)">
        <label class="form-check-label" for="inlineRadio2">Full remote</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="inlineRadio3" [value]="2" (change)="onCheckboxChange($event, remote)" [checked]="isSelected(2, remote)">
        <label class="form-check-label" for="inlineRadio3">Hybride</label>
      </div>
    </div>

    <ng-container *ngIf="displayCustomRemote">
      <label class="form-check-label mt-2" for="inlineRadio3">Rythme remote (jours)</label>
      <div class="custom-slider">
        <ngx-slider [formControl]="formDay" [options]="optionsDays" (valueChange)="updateMinDay($event)" (highValueChange)="updateMaxDay($event)"></ngx-slider>
      </div>
    </ng-container>
  </ng-container>
</form>
<div class="mt-4" *ngIf="displayCustomRemote && checkbox == false" >
  <ng-content select="[enterprise]"></ng-content>
  <ng-content select="[candidate]"></ng-content>

  <div class="mx-lg-2">
    <div class="custom-slider">
      <ngx-slider class="" style="margin: 35px 0 40px 0" [formControl]="formDay" [options]="optionsDays" (valueChange)="updateMinDay($event)" (highValueChange)="updateMaxDay($event)"></ngx-slider>
    </div>
  </div>
</div>

