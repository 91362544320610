import {NgModule} from '@angular/core';
import {ModalAvailabilityCandidateComponent} from "./modal-availability-candidate.component";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {LoadingButtonModule} from "../loading-button/loading-button.module";
import {NgSelectModule} from "@ng-select/ng-select";

@NgModule({
  imports: [CommonModule, SharedModule, FontAwesomeModule, LoadingButtonModule, NgSelectModule],
  declarations: [ModalAvailabilityCandidateComponent],
  exports: [ModalAvailabilityCandidateComponent],
})
export class ModalAvailabilityCandidateModule {}
