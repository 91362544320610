<span (click)="show()">
  <ng-content></ng-content>
</span>
<div class="modal modal-lg fade" id="exampleModal" tabindex="-1"  [attr.data-bs-backdrop]="backdrop" aria-labelledby="exampleModalLabel" aria-hidden="true" role="dialog" data-bs-parent="body" #modal>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="d-flex gap-2">
          <fa-icon [icon]="fa.faCircle" style="color: #F14848"></fa-icon>
          <h2 style="line-height: 1.2">Motif de votre indisponibilité</h2>
        </div>

        <div class="mt-3">
          <form class="mt-2" [formGroup]="formRefuse" (submit)="submit()">
            <ng-select [items]="reasons" (click)="$event.stopImmediatePropagation()"
                       bindLabel="title"
                       bindValue="key"
                       id="level"
                       [multiple]="false"
                       [clearSearchOnAdd]="true"
                       [clearable]="false"
                       [closeOnSelect]="true"
                       [addTag]="false"
                       formControlName="reason"
                       placeholder="Sélectionner">
            </ng-select>

            <ng-container *ngIf="displayOtherReason">
              <label class="col-form-label required">Précisez</label>
              <textarea rows="3"  formControlName="comment" class="form-control" [ngClass]="{'is-invalid' : (comment.dirty || comment.touched) && comment.errors}"></textarea>
              <div *ngIf="comment?.invalid && (comment?.dirty || comment?.touched)" class="invalid-feedback">
                <div *ngIf="comment?.hasError('required')">
                  Ce champ est obligatoire
                </div>
                <div *ngIf="comment?.errors?.['serverError']">
                  {{comment.errors?.['serverError']}}
                </div>
              </div>
            </ng-container>

            <div class="d-flex gap-2 mt-4 d-flex justify-content-end">
              <app-loading-button [loading]="loading" [class]="'btn btn-primary'" [type]="'submit'">
                Confirmer mon choix
              </app-loading-button>
              <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Annuler">Annuler</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
