import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromSubscriberSearchReducer from '../reducers/subscriberSearch.reducer';

export const getSubscriberSearchState = createFeatureSelector<fromSubscriberSearchReducer.State>('subscriberSearch');

export const selectLoading = createSelector(getSubscriberSearchState, (state: fromSubscriberSearchReducer.State) => state.loading);

export const selectError = createSelector(getSubscriberSearchState, (state: fromSubscriberSearchReducer.State) => state.error);

export const selectAll = createSelector(getSubscriberSearchState, (state: fromSubscriberSearchReducer.State) => state.all);

export const selectCurrent = createSelector(getSubscriberSearchState, (state: fromSubscriberSearchReducer.State) => state.current);
