import {NgModule} from '@angular/core';
import {ProfileCandidateMenuComponent} from "./profile-candidate-menu.component";
import {CommonModule} from "@angular/common";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {RouterModule} from "@angular/router";
import {StatusCandidateModule} from "../../../shared/ui/status-candidate/status-candidate.module";

@NgModule({
  imports: [CommonModule, FontAwesomeModule, RouterModule, StatusCandidateModule],
  declarations: [ProfileCandidateMenuComponent],
  exports: [ProfileCandidateMenuComponent],
})
export class ProfileCandidateMenuModule {}
