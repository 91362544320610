import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  protected API_URL = environment.API_URL;
  protected PATH = '/template/'

  constructor(protected http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get<any>(this.API_URL + this.PATH)
  }

  create(data: any): Observable<any> {
    return this.http.post<any>(this.API_URL + this.PATH, data)
  }

  update(id: string, data: any): Observable<any> {
    return this.http.patch<any>(this.API_URL + this.PATH + id , data)
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(this.API_URL + this.PATH + id)
  }
}
