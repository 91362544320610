import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {BrowserTracing} from "@sentry/tracing";
import {HttpErrorResponse} from "@angular/common/http";

Sentry.init({
  dsn: "https://4ed58bd37f9c415aad202e0f04d3f464@o1294169.ingest.sentry.io/4504835963486208",
  environment: environment.NAME,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [environment.APP_URL],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay()
  ],
  ignoreErrors: [/Http failure/],
  beforeSend(event, hint) {
    // Handled by NgModule's ErrorHandler
    if (hint?.originalException instanceof HttpErrorResponse) {
      return null;
    }
    return event;
  },
  enabled: environment.NAME !== 'dev',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});
if (environment.production) {
  // enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
