import {createReducer, on} from '@ngrx/store';
import * as CProfileActions from '../actions/cProfile.actions';
import {CProfileModel} from "../models/cProfile.model";

export const EProfileFeatureKey = 'cProfile';

export interface State {
  me: CProfileModel | null
  current: CProfileModel | null,
  loading: boolean,
  error: any
}

export const initialState: State = {
  me: null,
  current: null,
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(CProfileActions.loadMe, (state) => ({...state, loading: true, error: null})),
  on(CProfileActions.loadMeSuccess, (state, {data}) => ({
    ...state,
    me: data,
    loading: false,
    error: null
  })),
  on(CProfileActions.loadMeFailure, (state, {error}) => ({...state, loading: false, error})),
  on(CProfileActions.getCProfile, (state) => ({...state, loading: true, error: null})),
  on(CProfileActions.getCProfileSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(CProfileActions.getCProfileFailure, (state, {error}) => ({...state, loading: false, error})),
  on(CProfileActions.getCProfileGuest, (state) => ({...state, loading: true, error: null})),
  on(CProfileActions.getCProfileGuestSuccess, (state, {cProfile}) => ({
    ...state,
    current: cProfile,
    loading: false,
    error: null
  })),
  on(CProfileActions.getCProfileGuestFailure, (state, {error}) => ({...state, loading: false, error})),
);


