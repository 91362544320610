import {ChangeDetectorRef, Injectable} from "@angular/core";
import {Store} from "./store";
import {EProfileService} from "./e-profile.service";

@Injectable()
export class EProfileStore extends Store<any|null> {

  constructor (protected eProfileService: EProfileService, protected ref: ChangeDetectorRef) {
    super(null);
  }

  get(): void {
    this.eProfileService.get().subscribe((data: any) => {
      this.setState(data)
      this.ref.markForCheck()
    })
  }

  setFavorite(id: string): void {
    this.eProfileService.setFavorite(id).subscribe((data: any) => {
      this.ref.markForCheck()
    })
  }
}
