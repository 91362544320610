import {NgModule} from '@angular/core';
import {NotificationItemComponent} from "./notification-item.component";
import {CommonModule} from "@angular/common";
import {AvatarModule} from "../avatar/avatar.module";
import {RouterModule} from "@angular/router";

@NgModule({
    imports: [CommonModule, AvatarModule, RouterModule],
  declarations: [NotificationItemComponent],
  exports: [NotificationItemComponent],
})
export class NotificationItemModule {}
