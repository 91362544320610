import {NgModule} from '@angular/core';
import {ReadMoreComponent} from "./read-more.component";
import {CommonModule} from "@angular/common";
import {QuillViewComponent} from "ngx-quill";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [ReadMoreComponent],
  exports: [ReadMoreComponent],
})
export class ReadMoreModule {}
