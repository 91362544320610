import {AfterViewChecked, ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {faBuildingUser, faInfoSquare, faListDropdown, faTags, faUserTie} from "@fortawesome/pro-light-svg-icons";
import {Store as StoreNgrx} from "@ngrx/store";
import * as EProfileSelectors from "../../../core/selectors/eProfile.selectors"

@Component({
  selector: 'app-profile-enterprise-menu',
  templateUrl: './profile-enterprise-menu.component.html',
  styleUrls: ['./profile-enterprise-menu.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileEnterpriseMenuComponent implements AfterViewChecked {

  fa = {faListDropdown, faInfoSquare, faUserTie, faBuildingUser, faTags}

  @Input()
  subscriber: any = null

  @Input()
  offcanvas: boolean = false

  eProfile$ = this.storeNgrx.select(EProfileSelectors.selectMe)

  jofOfferEmpty: boolean = false
  collaboratorsVisited: boolean = false
  brandEmpty: boolean = false
  planBillingVisited: boolean = false

  constructor(private storeNgrx: StoreNgrx) {
  }

  ngAfterViewChecked() {
    this.jofOfferEmpty = localStorage.getItem('jofOfferNotEmpty') !== 'true';
    this.collaboratorsVisited = localStorage.getItem('collaboratorsVisited') !== 'true';
    this.brandEmpty = localStorage.getItem('brandNotEmpty') !== 'true';
    this.planBillingVisited = localStorage.getItem('planBillingVisited') !== 'true';
  }
}
