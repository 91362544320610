import {NgModule} from '@angular/core';
import {FormSkillComponent} from "./form-skill.component";
import {SharedModule} from "../../shared.module";
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {NgSelectModule} from "@ng-select/ng-select";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DragulaModule} from "ng2-dragula";

@NgModule({
    imports: [SharedModule, NgSelectModule, FontAwesomeModule, DragulaModule.forRoot()],
  declarations: [FormSkillComponent],
  exports: [FormSkillComponent],
})
export class FormSkillModule {}
