import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromPropositionReducer from '../reducers/proposition.reducer';

export const getPropositionState = createFeatureSelector<fromPropositionReducer.State>('proposition');

export const selectLoading = createSelector(getPropositionState, (state: fromPropositionReducer.State) => state.loading);

export const selectError = createSelector(getPropositionState, (state: fromPropositionReducer.State) => state.error);

export const selectCurrent = createSelector(getPropositionState, (state: fromPropositionReducer.State) => state.current);

export const selectAll = createSelector(getPropositionState, (state: fromPropositionReducer.State) => state.all);

