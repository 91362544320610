import {NgModule} from '@angular/core';
import {OffCanvasNotificationsComponent} from "./offCanvas-notifications.component";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared.module";
import {AvatarModule} from "../avatar/avatar.module";
import {NotificationItemModule} from "../notification-item/notification-item.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@NgModule({
    imports: [CommonModule, SharedModule, AvatarModule, NotificationItemModule, FontAwesomeModule],
  declarations: [OffCanvasNotificationsComponent],
  exports: [OffCanvasNotificationsComponent],
})
export class OffCanvasNotificationsModule {}
