import {createAction, props} from '@ngrx/store';

export const getJobOffers = createAction(
  '[JobOffer] Get JobOffers'
);

export const getJobOffersSuccess = createAction(
  '[JobOffer] Get JobOffers Success',
  props<{ data: any }>()
);

export const getJobOffersFailure = createAction(
  '[JobOffer] Get JobOffers Failure',
  props<{ error: any }>()
);
