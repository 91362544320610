import {createReducer, on} from '@ngrx/store';
import * as EProfileActions from '../actions/eProfile.actions';
import {EProfileModel} from "../models/eProfile.model";
import {QuotaModel} from "../models/quota.model";
import {InvoiceModel} from "../models/stripe/invoice.model";
import {PushModel} from "../models/push.model";

export const EProfileFeatureKey = 'eProfile';

export interface State {
  me: EProfileModel | null,
  quota: QuotaModel | null,
  pushes: PushModel[],
  business: number,
  invoices: InvoiceModel[],
  loading: boolean,
  error: any
}

export const initialState: State = {
  me: null,
  quota: null,
  pushes: [],
  business: localStorage.getItem('business') === null ? 1 : Number(localStorage.getItem('business')),
  invoices: [],
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(EProfileActions.loadMe, (state) => ({...state, loading: true, error: null})),
  on(EProfileActions.loadMeSuccess, (state, {data}) => ({
    ...state,
    me: data,
    loading: false,
    error: null
  })),
  on(EProfileActions.loadMeFailure, (state, {error}) => ({...state, loading: false, error})),
  on(EProfileActions.getQuota, (state) => ({...state, loading: true, error: null})),
  on(EProfileActions.getQuotaSuccess, (state, {data}) => ({
    ...state,
    quota: data,
    loading: false,
    error: null
  })),
  on(EProfileActions.getQuotaFailure, (state, {error}) => ({...state, loading: false, error})),
  on(EProfileActions.updateBusiness, (state) => ({...state, loading: true, error: null})),
  on(EProfileActions.updateBusinessSuccess, (state, {data}) => ({
    ...state,
    me: data,
    loading: false,
    error: null
  })),
  on(EProfileActions.updateBusinessFailure, (state, {error}) => ({...state, loading: false, error})),
  on(EProfileActions.updateBusinessSelected, (state, {business}) => ({
    ...state,
    business: business,
    loading: false,
    error: null
  })),
  on(EProfileActions.updateBillingDetails, (state) => ({...state, loading: true, error: null})),
  on(EProfileActions.updateBillingDetailsSuccess, (state, {data}) => ({
    ...state,
    me: {...data},
    loading: false,
    error: null
  })),
  on(EProfileActions.updateBillingDetailsFailure, (state, {error}) => ({...state, loading: false, error})),
  on(EProfileActions.getInvoices, (state) => ({...state, loading: true, error: null})),
  on(EProfileActions.getInvoicesSuccess, (state, {data}) => ({
    ...state,
    invoices: [...data],
    loading: false,
    error: null
  })),
  on(EProfileActions.getInvoicesFailure, (state, {error}) => ({...state, loading: false, error})),
  on(EProfileActions.getPushes, (state) => ({...state, loading: true, error: null})),
  on(EProfileActions.getPushesSuccess, (state, {data}) => ({
    ...state,
    pushes: data,
    loading: false,
    error: null
  })),
  on(EProfileActions.getPushesFailure, (state, {error}) => ({...state, loading: false, error})),
);
