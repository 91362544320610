import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {faBell, faLock, faUnlock} from "@fortawesome/pro-light-svg-icons";
import {SubscriberState} from "../../../state/subscriber-state.service";
import {Store} from "@ngxs/store";
import {BehaviorSubject, Observable, Subject, takeUntil} from "rxjs";
import {ActivatedRoute} from "@angular/router";

declare var bootstrap: any

@Component({
  selector: 'app-offCanvas-notifications',
  templateUrl: './offCanvas-notifications.component.html',
  styleUrls: ['./offCanvas-notifications.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
export class OffCanvasNotificationsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  fa = {faLock, faUnlock, faBell}

  @Input()
  notifications$: Observable<any>

  @Input()
  read: any

  @Input()
  readAll: any

  @ViewChild('offCanvas')
  offCanvas: ElementRef

  @Input()
  notificationDisplay$ : BehaviorSubject<boolean>

  offCanvasLoad: any

  display = false

  subscriber: any = null

  ngUnsubscribe = new Subject<void>();

  constructor(private ref: ChangeDetectorRef, private store: Store, private activatedRoute: ActivatedRoute) {
    this.subscriber = this.store.selectSnapshot(SubscriberState)

    setTimeout(() => {
      if (this.activatedRoute.snapshot.queryParamMap.get('notifications')) {
        this.offCanvasLoad.show()
      }
    }, 300)
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngAfterViewInit(): void {
    this.offCanvasLoad = new bootstrap.Offcanvas(this.offCanvas.nativeElement)

    this.offCanvas.nativeElement.addEventListener('show.bs.offcanvas', () => {
      this.display = true
      this.notificationDisplay$.next(true)
      this.ref.markForCheck()
    })

    this.offCanvas.nativeElement.addEventListener('hidden.bs.offcanvas', () => {
      this.display = false
      this.notificationDisplay$.next(false)
      this.ref.markForCheck()
    })
  }

  toggle() {
    this.offCanvasLoad.toggle()
  }

  hide() {
    this.offCanvasLoad.hide()
  }

  markAsRead(notification: any) {
    this.read(notification)
    this.toggle()
  }

  markAsAllRead() {
    this.readAll()
    this.toggle()
  }

  ngOnDestroy(): void {
    if (this.offCanvasLoad) {
      this.offCanvasLoad.hide()
    }

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
