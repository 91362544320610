import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AuthInterceptor} from "./services/auth.interceptor";
import {environment} from "../environments/environment";
import {NgxsModule} from "@ngxs/store";
import {AuthState} from "./state/auth.state";
import {NgxsStoragePluginModule} from "@ngxs/storage-plugin";
import {SubscriberState} from "./state/subscriber-state.service";
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {SharedModule} from "./shared/shared.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ServiceWorkerModule} from '@angular/service-worker';
import {PromptUpdateService} from "./prompt-update.service";
import {NgxScrollPositionRestorationModule} from "ngx-scroll-position-restoration";
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {QuillConfigModule, QuillModule} from "ngx-quill";
import {NgxsLoggerPluginModule} from "@ngxs/logger-plugin";
import {SidebarMenuModule} from "./shared/ui/sidebar-menu/sidebar-menu.module";
import {ToastrModule} from "ngx-toastr";
import {ToastComponent} from "./shared/ui/toast/toast.component";
import {Router} from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import {CheckForUpdateService} from "./check-for-update.service";
import {StoreModule} from "@ngrx/store";
import {metaReducers, reducers} from "./reducers";
import {EffectsModule} from "@ngrx/effects";
import {PixelSearchMatchEffects} from "./core/effects/pixelSearchMatch.effects";
import {EProfileEffects} from "./core/effects/eProfile.effects";
import {CommonEffects} from "./core/effects/common.effects";
import {PixelSearchEffects} from "./core/effects/pixelSearch.effects";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {CProfileEffects} from "./core/effects/cProfile.effects";
import {SearchEffects} from "./core/effects/search.effects";
import {SubscriptionEffects} from "./core/effects/subscription.effects";
import {JobOfferEffects} from "./core/effects/jobOffer.effects";
import {PropositionEffects} from "./core/effects/proposition.effects";
import {SubscriberSearchEffects} from "./core/effects/subscriberSearch.effects";

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    ToastComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgxScrollPositionRestorationModule.forRoot(),
    NgxsModule.forRoot([AuthState, SubscriberState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: [AuthState]
    }),
    NgxsLoggerPluginModule.forRoot({logger: console, collapsed: false, disabled: environment.production}),
    SharedModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:3000'
    }),
    NgxSliderModule,
    QuillModule.forRoot(),
    QuillConfigModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          [{'list': 'ordered'}, {'list': 'bullet'}],
          [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
          [{'align': []}],
        ]
      },
      placeholder: 'Ajoutez votre texte ici...'
    }),
    SidebarMenuModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      // disableTimeOut: true,
      toastComponent: ToastComponent,
      positionClass: 'toast-top-right',
      // toastClass: 'position-fixed'
    }),
    StoreModule.forRoot(reducers, {metaReducers}),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      PixelSearchMatchEffects, PixelSearchEffects, EProfileEffects, CProfileEffects, CommonEffects,
      SearchEffects, SubscriptionEffects, JobOfferEffects, PropositionEffects, SubscriberSearchEffects
    ]),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    CheckForUpdateService,
    PromptUpdateService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
