import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as PixelSearchActions from '../actions/pixelSearch.actions';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from "@ngrx/store";
import {PixelSearchService} from "../apis/pixelSearch.service";

@Injectable()
export class PixelSearchEffects {
  constructor(private actions$: Actions, private pixelSearchService: PixelSearchService, private store: Store) {
  }

  load$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PixelSearchActions.loadPixelSearch),
        map((action: any) => action),
        mergeMap(() => {
          return this.pixelSearchService.load().pipe(
            map(data => PixelSearchActions.loadPixelSearchSuccess({data})),
            catchError(error => of(PixelSearchActions.loadPixelSearchFailure({error})))
          );
        })
      )
    }
  );

  create$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PixelSearchActions.createPixelSearch),
        map((action: any) => action),
        mergeMap(({id, skills, experience_min, experience_max, salary_min, salary_max}) => {
          return this.pixelSearchService.create(id, skills, experience_min, experience_max, salary_min, salary_max).pipe(
            map(data => PixelSearchActions.createPixelSearchSuccess({data})),
            catchError(error => of(PixelSearchActions.createPixelSearchFailure({error})))
          );
        })
      )
    }
  );

  update$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PixelSearchActions.updatePixelSearch),
        map((action: any) => action),
        mergeMap(({id, skills, experience_min, experience_max, salary_min, salary_max}) => {
          return this.pixelSearchService.update(id, skills, experience_min, experience_max, salary_min, salary_max).pipe(
            map(data => PixelSearchActions.updatePixelSearchSuccess({data})),
            catchError(error => of(PixelSearchActions.updatePixelSearchFailure({error})))
          );
        })
      )
    }
  );

  estimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PixelSearchActions.getEstimatePixelSearch),
      switchMap(({ skills, experience_min, experience_max, salary_min, salary_max }) =>
        this.pixelSearchService.estimate(skills, experience_min, experience_max, salary_min, salary_max).pipe(
          map(data => PixelSearchActions.getEstimatePixelSearchSuccess({ data })),
          catchError(error => of(PixelSearchActions.getEstimatePixelSearchFailure({ error })))
        )
      )
    )
  );
}
