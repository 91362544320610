import {NgModule} from '@angular/core';
import {ModalSearchComponent} from "./modal-search.component";
import {CommonModule} from "@angular/common";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {LoadingButtonModule} from "../../../shared/ui/loading-button/loading-button.module";
import {SharedModule} from "../../../shared/shared.module";

@NgModule({
  imports: [CommonModule, SharedModule, FontAwesomeModule, LoadingButtonModule],
  declarations: [ModalSearchComponent],
  exports: [ModalSearchComponent],
})
export class ModalSearchModule {}
