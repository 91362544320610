import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as JobOfferActions from '../actions/jobOffer.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from "@ngrx/store";
import {JobOfferService} from "../apis/jobOffer.service";

@Injectable()
export class JobOfferEffects {
  constructor(private actions$: Actions, private jobOfferService: JobOfferService, private store: Store) {
  }

  jobOffers$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(JobOfferActions.getJobOffers),
        map((action: any) => action),
        mergeMap(() => {
          return this.jobOfferService.index().pipe(
            map(data => JobOfferActions.getJobOffersSuccess({data})),
            catchError(error => of(JobOfferActions.getJobOffersFailure({error})))
          );
        })
      )
    }
  );
}
