import {createAction, props} from '@ngrx/store';
import {PixelSearchModel} from "../models/pixelSearch.model";
import {SkillModel} from "../models/skill.model";

export const loadPixelSearch = createAction(
  '[PixelSearch] Load PixelSearch'
);

export const loadPixelSearchSuccess = createAction(
  '[PixelSearch] Load PixelSearch Success',
  props<{ data: PixelSearchModel[] }>()
);

export const loadPixelSearchFailure = createAction(
  '[PixelSearch] Load PixelSearch Failure',
  props<{ error: any }>()
);

export const createPixelSearch = createAction(
  '[PixelSearch] Create PixelSearch',
  props<{ skills: any[], experience_min: number, experience_max: number, salary_min: number, salary_max: number }>()
);

export const createPixelSearchSuccess = createAction(
  '[PixelSearch] Create PixelSearch Success',
  props<{ data: any }>()
);

export const createPixelSearchFailure = createAction(
  '[PixelSearch] Create PixelSearch Failure',
  props<{ error: any }>()
);

export const updatePixelSearch = createAction(
  '[PixelSearch] Update PixelSearch',
  props<{ id: string, skills: any[], experience_min: number, experience_max: number, salary_min: number, salary_max: number }>()
);

export const updatePixelSearchSuccess = createAction(
  '[PixelSearch] Update PixelSearch Success',
  props<{ data: any }>()
);

export const updatePixelSearchFailure = createAction(
  '[PixelSearch] Update PixelSearch Failure',
  props<{ error: any }>()
);

export const getEstimatePixelSearch = createAction(
  '[PixelSearch] Get Estimate PixelSearch search',
  props<{ skills: SkillModel[], experience_min: number, experience_max: number, salary_min: number|null, salary_max: number|null }>()
);

export const getEstimatePixelSearchSuccess = createAction(
  '[PixelSearch] Get Estimate PixelSearch Success',
  props<{ data: any }>()
);

export const getEstimatePixelSearchFailure = createAction(
  '[PixelSearch] Get Estimate PixelSearch Failure',
  props<{ error: any }>()
);
//
// export const setHit = createAction(
//   '[PixelSearch] Get Estimate PixelSearch Failure',
//   props<{ error: any }>()
// );
