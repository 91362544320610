import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

const API_URL = environment.API_URL;
const PATH = '/subscription/'

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get<any>(API_URL + PATH)
  }

  update(price_id: string) {
    return this.http.post<any>(API_URL + PATH + 'update', {price_id})
  }

  cancel() {
    return this.http.get<any>(API_URL + PATH + 'cancel')
  }

  prices() {
    return this.http.get<any>(API_URL + PATH + 'prices')
  }
}
