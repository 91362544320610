import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NotFoundPage} from "./error/feature/404/not-found.page";
import {RedirectComponent} from "./shared/ui/redirect/redirect.component";

const routes: Routes = [
  { path: '', component: RedirectComponent, pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/feature/dashboard-shell/dashboard-shell.module').then(m => m.DashboardShellModule)
  },
  {
    path: 'recruitment',
    loadChildren: () => import('./recruitment/feature/recruitment-shell/recruitment-shell.module').then(m => m.RecruitmentShellModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/feature/account-shell/account-shell.module').then(m => m.AccountShellModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/feature/profile-shell/profile-shell.module').then(m => m.ProfileShellModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/feature/search-shell/search-shell.module').then(m => m.SearchShellModule)
  },
  {
    path: 'negotiation',
    loadChildren: () => import('./negotiations/feature/negotiations-shell/negotiations-routing.module').then(m => m.NegotiationsRoutingModule)
  },
  {
    path: 'candidate',
    loadChildren: () => import('./candidate/feature/candidate-shell/candidate-routing.module').then(m => m.CandidateRoutingModule)
  },
  {
    path: 'enterprise',
    loadChildren: () => import('./enterprise/feature/enterprise-shell/enterprise-routing.module').then(m => m.EnterpriseRoutingModule)
  },
  // {
  //   path: '',
  //   loadChildren: () => import('./landing-page/feature/landing-page-shell/landing-page-shell.module').then(m => m.LandingPageShellModule)
  // },
  {
    path: '',
    loadChildren: () => import('./auth/feature/auth-shell/auth-shell.module').then(m => m.AuthShellModule)
  },
  {
    path: '',
    loadChildren: () => import('./error/feature/error-shell/error-shell.module').then(m => m.ErrorShellModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/feature/signup-shell/signup-shell.module').then(m => m.SignupShellModule)
  },
  {
    path: 'recommendation',
    loadChildren: () => import('./recommendation/feature/recommendation-shell/recommendation-shell.module').then(m => m.RecommendationShellModule)
  },
  {
    path: '**', pathMatch: 'full',
    component: NotFoundPage
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    // scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
