import {NgModule} from '@angular/core';
import {LoadingButtonComponent} from "./loading-button.component";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared.module";

@NgModule({
    imports: [CommonModule, SharedModule],
  declarations: [LoadingButtonComponent],
  exports: [LoadingButtonComponent],
})
export class LoadingButtonModule {}
