<ng-container *ngIf="countSkills() < max">
  <ng-select id="skills" [class]="class" [items]="skillsItems" #tmpSelectedSkill
             bindLabel="title"
             (change)="addSkill($event, isFavorite)"
             bindValue="id"
             appendTo="body"
             [addTag]="canAddSkill"
             [closeOnSelect]="true"
             [multiple]="false"
             [clearable]="false"
             placeholder="Rechercher"
             [ngClass]="{'is-invalid' : (skills.dirty || skills.touched) && skills.errors}">
    <ng-template ng-optgroup-tmp let-item="item">
      <span class="text-muted">{{item.title}}</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      {{item.title}} {{item.disabled}}
    </ng-template>
  </ng-select>
  <div *ngIf="skills?.invalid && (skills?.dirty || skills?.touched)" class="invalid-feedback">
    <div *ngIf="skills?.hasError('required')">
      Ce champ est obligatoire
    </div>
    <div *ngIf="skills?.errors?.['serverError']">
      {{skills.errors?.['serverError']}}
    </div>
  </div>

</ng-container>

<ng-container *ngIf="displaySkills">
  <ng-container *ngIf="draggable; else withOutDraggable">
    <div class="mt-3" *ngIf="skillsFavorite.length">

      <table class="table table-borderless table-dashed m-0">
        <tbody dragula="SKILLS" [(dragulaModel)]="skillsFavorite">
        <ng-container *ngFor="let skill of (skillsFavorite); let i = index">
          <tr *ngIf="skill.is_favorite === isFavorite" class="align-middle">
            <td style="width: 70px;cursor: grab" >
              <div class="d-flex gap-2 justify-content-between">
                <fa-icon [icon]="fa.faGripDotsVertical"></fa-icon>
                <div class="color-tertiary fw-bold">
                  {{i + 1}}
                </div>
                <fa-icon class="color-tertiary" [icon]="fa.faCircleSmall" size="xs"></fa-icon>
              </div>
            </td>
            <td class="color-tertiary fw-bold ps-0">
              <div class="d-flex gap-2 align-items-center">
                <div>
                  {{skill.title}}
                </div>
              </div>
            </td>
            <td class="justify-content-end d-flex gap-2 flex-wrap" *ngIf="withLevel">
              <div class="tag-sm tag-secondary pointer" [ngClass]="skill.level && skill.level[0] === 0 ? 'selected' : ''" (click)="setSkillLevel(skill, 0)">0-2 ans</div>
              <div class="tag-sm tag-secondary pointer" [ngClass]="skill.level && skill.level[0] === 2 ? 'selected' : ''" (click)="setSkillLevel(skill, 2)">2-4 ans</div>
              <div class="tag-sm tag-secondary pointer" [ngClass]="skill.level && skill.level[0] === 4 ? 'selected' : ''" (click)="setSkillLevel(skill, 4)">4-6 ans</div>
              <div class="tag-sm tag-secondary pointer" [ngClass]="skill.level && skill.level[0] === 6 ? 'selected' : ''" (click)="setSkillLevel(skill, 6)">6-8 ans</div>
              <div class="tag-sm tag-secondary pointer" [ngClass]="skill.level && skill.level[0] === 8 ? 'selected' : ''" (click)="setSkillLevel(skill, 8)">+8 ans</div>
            </td>
            <td class="text-end " style="width: 30px">
              <div (click)="removeSkill(skill)" class="pointer">
                <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
              </div>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>

    </div>
  </ng-container>
  <ng-template #withOutDraggable>
    <ng-container *ngIf="displayPreview">
      <ng-container *ngIf="isFavorite">
        <div class="mt-4 d-flex flex-wrap gap-2" *ngIf="skillsFavorite.length">
          <ng-container *ngFor="let skill of skillsFavorite">
            <div *ngIf="skill.is_favorite === isFavorite" class="tag tag-secondary selected pointer" (click)="removeSkill(skill)">
              {{skill.title}} <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!isFavorite">
        <div class="mt-4 d-flex flex-wrap gap-2" *ngIf="skillsAdd.length">
          <ng-container *ngFor="let skill of skillsAdd">
            <div *ngIf="skill.is_favorite === isFavorite" class="tag tag-secondary selected pointer" (click)="removeSkill(skill)">
              {{skill.title}} <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
