import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {CProfileModel} from "../models/cProfile.model";


const API_URL = environment.API_URL;
const PATH = '/cprofile/'

@Injectable({
  providedIn: 'root'
})
export class CProfileService {

  constructor(private http: HttpClient) {
  }

  me() {
    return this.http.get<CProfileModel>(API_URL + PATH + 'me')
  }

  get(id: number) {
    return this.http.get<CProfileModel>(API_URL + PATH + id)
  }

  getGuest(id: number) {
    return this.http.get<CProfileModel>(API_URL + PATH + id + '/guest')
  }

  updateFavorite(id: string) {
    return this.http.patch<CProfileModel>(API_URL + PATH + id + '/favorite/', {})
  }

  updateRejected(id: any) {
    return this.http.patch<CProfileModel>(API_URL + PATH + id + '/rejected/', {})
  }
}
