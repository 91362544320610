import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as PixelSearchMatchActions from '../actions/pixelSearchMatch.actions';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {select, Store} from "@ngrx/store";
import {PixelSearchMatchService} from "../apis/pixelSearchMatch.service";
import * as PixelSearchSelectors from "../selectors/pixelSearch.selectors";
import * as PixelSearchMatchSelectors from "../selectors/pixelSearchMatch.selectors";
import {PixelSearchMatchModel} from "../models/pixelSearchMatch.model";
import * as PixelSearchActions from "../actions/pixelSearch.actions";

@Injectable()
export class PixelSearchMatchEffects {
  constructor(private actions$: Actions, private pixelSearchMatchService: PixelSearchMatchService, private store: Store) {
  }

  loadHistory$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PixelSearchMatchActions.loadHistory),
        map((action: any) => action),
        mergeMap(() => {
          return this.pixelSearchMatchService.history().pipe(
            map(data => PixelSearchMatchActions.loadHistorySuccess({data})),
            catchError(error => of(PixelSearchMatchActions.loadHistoryFailure({error})))
          );
        })
      )
    }
  );

  refuseMatch$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PixelSearchMatchActions.deletePixelSearchMatch),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.pixelSearchMatchService.delete(id).pipe(
            map(data => PixelSearchMatchActions.deletePixelSearchMatchSuccess({data})),
            catchError(error => of(PixelSearchMatchActions.deletePixelSearchMatchFailure({error})))
          );
        })
      )
    }
  );

  bookmark$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PixelSearchMatchActions.bookmarkPixelSearchMatch),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.pixelSearchMatchService.bookmark(id).pipe(
            map(data => PixelSearchMatchActions.bookmarkPixelSearchMatchSuccess({data})),
            catchError(error => of(PixelSearchMatchActions.bookmarkPixelSearchMatchFailure({error})))
          );
        })
      )
    }
  );

  bookmarkSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PixelSearchMatchActions.bookmarkPixelSearchMatchSuccess),
        withLatestFrom(this.store.pipe(select(PixelSearchSelectors.selectAll))),
        mergeMap(([action, pixelSearches]) => {

          if (pixelSearches.length) {

            const pixelSearchIndex = pixelSearches.findIndex(ps => ps.id === action.data.pixel_search_id);

            if (pixelSearchIndex !== -1) {

              const matches: PixelSearchMatchModel[] = [];
              for (const match of pixelSearches[pixelSearchIndex].matches) {
                matches.push({...match});
              }
              const matchIndex = matches.findIndex(match => match.id === action.data.id);

              if (matchIndex !== -1) {
                matches[matchIndex] = {...action.data};

                const updatedPixelSearches = [...pixelSearches];
                updatedPixelSearches[pixelSearchIndex] = {...pixelSearches[pixelSearchIndex], matches};

                this.store.dispatch(PixelSearchActions.loadPixelSearchSuccess({data: updatedPixelSearches}))
              }
            }
          }

          return of({type: 'NO_ACTION'});
        }),
      );
    },
    {dispatch: false}
  );

  bookmarkSuccess2$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PixelSearchMatchActions.bookmarkPixelSearchMatchSuccess),
        withLatestFrom(this.store.pipe(select(PixelSearchMatchSelectors.selectHistory))),
        mergeMap(([action, pixelSearchMatches]) => {

          if (pixelSearchMatches.length) {
            const pixelSearchMatchIndex = pixelSearchMatches.findIndex(psm => psm.id === action.data.id);

            if (pixelSearchMatchIndex !== -1) {
              const matches = [...pixelSearchMatches]
              matches[pixelSearchMatchIndex] = {...action.data}

              this.store.dispatch(PixelSearchMatchActions.loadHistorySuccess({data: matches}))
            }
          }

          return of({ type: 'NO_ACTION' });
        }),

      );
    },
    {dispatch: false}
  );
}
