<section class="authentication-bg min-vh-100 py-5 py-lg-0">
  <div class="bg-overlay bg-light"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mb-5">
              <h1 class="error-title">
                <span class="blink-infinite">404</span>
              </h1>
              <h4 class="text-uppercase">Sorry, page not found</h4>
              <div class="mt-5 text-center">
                <a class="btn btn-primary waves-effect waves-light" (click)="back()">Back to previous</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8 col-xl-7">
            <div class="mt-2">
              <img src="assets/images/error-img.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
