import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  protected API_URL = environment.API_URL;
  protected PATH = '/notification/'

  constructor(protected http: HttpClient) { }

  getAll() {
    return this.http.get<any>(this.API_URL + this.PATH)
  }

  read(id: string) {
    return this.http.patch<any>(this.API_URL + this.PATH + id, {})
  }

  readAll() {
    return this.http.patch<any>(this.API_URL + this.PATH + 'all', {})
  }
}
