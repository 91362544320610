<ng-container *ngIf="subscriber">

  <ng-container *ngIf="target === 'menu'">
    <div class="d-flex justify-content-center align-items-center flex-column menu">
      <div class="avatar-{{size}}">
        <img *ngIf="subscriber.avatar" class="rounded-circle" [src]="('' | environment).DATA_URL + subscriber.avatar" oncontextmenu="return false;"/>
        <span *ngIf="!subscriber.avatar" class="font-size-{{size}} img-thumbnail rounded-circle avatar-title" >
          <span *ngIf="subscriber.first_name">
            {{subscriber.first_name[0] | uppercase}}
          </span>
          <span *ngIf="subscriber.last_name">
            {{subscriber.last_name[0] | uppercase}}
          </span>
       </span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="target === 'notification'">
    <div class="d-flex justify-content-center align-items-center flex-column notification">
      <div class="avatar-{{size}}">
        <img *ngIf="subscriber.avatar" class="rounded-circle" [src]="('' | environment).DATA_URL + subscriber.avatar" oncontextmenu="return false;"/>
        <span *ngIf="!subscriber.avatar" class="font-size-{{size}} img-thumbnail rounded-circle avatar-title" >
          <span *ngIf="subscriber.first_name">
            {{subscriber.first_name[0] | uppercase}}
          </span>
          <span *ngIf="subscriber.last_name">
            {{subscriber.last_name[0] | uppercase}}
          </span>
        </span>
      </div>
    </div>

  </ng-container>
  <ng-container *ngIf="target === 'account'">
    <div class="d-flex justify-content-center align-items-center flex-column account">
      <div class="avatar-{{size}}">
        <img *ngIf="subscriber.avatar" class="rounded-circle" [src]="('' | environment).DATA_URL + subscriber.avatar" oncontextmenu="return false;"/>
        <span *ngIf="!subscriber.avatar" class="font-size-{{size}} img-thumbnail rounded-circle avatar-title" >
          <span *ngIf="subscriber.first_name">
            {{subscriber.first_name[0] | uppercase}}
          </span>
          <span *ngIf="subscriber.last_name">
            {{subscriber.last_name[0] | uppercase}}
          </span>
        </span>
      </div>
    </div>

  </ng-container>
  <ng-container *ngIf="target === 'search'">
    <div class="d-flex justify-content-center align-items-center flex-column account">
      <div class="avatar-{{size}}">
        <img *ngIf="subscriber.avatar" class="rounded-circle" [src]="('' | environment).DATA_URL + subscriber.avatar" oncontextmenu="return false;"/>
        <span *ngIf="!subscriber.avatar" class="font-size-{{size}} img-thumbnail rounded-circle avatar-title" >
          <span *ngIf="subscriber.first_name">
            {{subscriber.first_name[0] | uppercase}}
          </span>
          <span *ngIf="subscriber.last_name">
            {{subscriber.last_name[0] | uppercase}}
          </span>
        </span>
      </div>
      <div style="margin-top: -15px" *ngIf="badge" [ngSwitch]="badge">
        <div *ngSwitchCase="'Vu'" class="badge bg-purple text-center" >{{badge}}</div>
        <div *ngSwitchCase="'Contacté'" class="badge bg-primary text-center text-white" style="background-color: var(--bs-primary) !important;" >{{badge}}</div>
      </div>
    </div>

  </ng-container>
  <ng-container *ngIf="target === 'messages'">
    <div class="d-flex justify-content-center align-items-center flex-column messages">
      <div class="avatar-{{size}}">
        <img *ngIf="subscriber.avatar" class="rounded-circle" [src]="('' | environment).DATA_URL + subscriber.avatar" oncontextmenu="return false;"/>
        <span *ngIf="!subscriber.avatar" class="font-size-{{size}} img-thumbnail rounded-circle avatar-title" >
          <span *ngIf="subscriber.first_name">
            {{subscriber.first_name[0] | uppercase}}
          </span>
          <span *ngIf="subscriber.last_name">
            {{subscriber.last_name[0] | uppercase}}
          </span>
       </span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="target === 'else'">
    <div class="d-flex justify-content-center align-items-center flex-column else">
      <div class="avatar-{{size}}">
        <img *ngIf="subscriber.avatar" class="rounded-circle" [src]="('' | environment).DATA_URL + subscriber.avatar" oncontextmenu="return false;"/>
        <span *ngIf="!subscriber.avatar" class="font-size-{{size}} img-thumbnail rounded-circle avatar-title" >
          <span *ngIf="subscriber.first_name">
            {{subscriber.first_name[0] | uppercase}}
          </span>
          <span *ngIf="subscriber.last_name">
            {{subscriber.last_name[0] | uppercase}}
          </span>
        </span>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="eProfile">
  <div class="d-flex justify-content-center align-items-center flex-column enterprise">
    <div class="avatar-{{size}}">
      <img *ngIf="eProfile.avatar" class="rounded-circle" [src]="('' | environment).DATA_URL + eProfile.avatar" oncontextmenu="return false;"/>
      <span *ngIf="!eProfile.avatar" class="font-size-{{size}} img-thumbnail rounded-circle avatar-title" >{{eProfile.enterprise | uppercase}}</span>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="user">
  <div class="d-flex justify-content-center align-items-center flex-column enterprise">
    <div class="avatar-{{size}}">
      <img class="rounded-circle" [src]="'assets/images/tm/' + user.id + '.png'"/>
    </div>
  </div>
</ng-container>
