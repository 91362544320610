import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store as StoreNgrx} from "@ngrx/store";
import {Subject, takeUntil} from "rxjs";
import {ToastrService} from "ngx-toastr";
import * as SubscriberSearchActions from "../../../core/actions/subscriberSearch.actions";
import * as SubscriberSearchSelectors from "../../../core/selectors/subscriberSearch.selectors";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {SubscriberSearchModel} from "../../../core/models/subscriberSearch.model";
import {Actions, ofType} from "@ngrx/effects";

declare var bootstrap: any

@Component({
  selector: 'app-modal-search',
  templateUrl: './modal-search.component.html',
  styleUrls: ['./modal-search.component.scss']
})
export class ModalSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  fa = {}

  @ViewChild('modal')
  modal: ElementRef

  subscriber: any

  modalLoad: any

  subscriberSearch: SubscriberSearchModel | null = null

  form = new FormGroup({
    id: new FormControl<string | null>(null),
    name: new FormControl<string>('', [Validators.required]),
  })

  loading$ = this.storeNgrx.select(SubscriberSearchSelectors.selectLoading)

  ngUnsubscribe = new Subject<void>();

  constructor(private ref: ChangeDetectorRef, private storeNgrx: StoreNgrx, private toast: ToastrService,
              private actions$: Actions) {

    this.actions$.pipe(
      ofType(
        SubscriberSearchActions.deleteSubscriberSearchSuccess,
        SubscriberSearchActions.storeSubscriberSearchSuccess,
        SubscriberSearchActions.updateSubscriberSearchSuccess
      ),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(action => {
      switch (action.type) {
        case SubscriberSearchActions.deleteSubscriberSearchSuccess.type:
          this.toast.success('Recherche supprimée', 'Confirmation')
          break;
        case SubscriberSearchActions.storeSubscriberSearchSuccess.type:
          this.toast.success('Recherche enregistrée', 'Confirmation')
          break;
        case SubscriberSearchActions.updateSubscriberSearchSuccess.type:
          this.toast.success('Mise à jour de la recherche', 'Confirmation')
          break;
      }
      this.hide()
      this.storeNgrx.dispatch(SubscriberSearchActions.loadSearches());
    });
  }

  show(subscriberSearch: SubscriberSearchModel): void {
    this.subscriberSearch = {...subscriberSearch}

    this.form.setValue({
      id: subscriberSearch.id,
      name: subscriberSearch.name
    })

    this.modalLoad.show()
  }

  hide() {
    this.modalLoad.hide()
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.modalLoad = new bootstrap.Modal(this.modal.nativeElement)

    let originalParent = this.modal.nativeElement.parentElement;

    this.modal.nativeElement.addEventListener('show.bs.modal', () => {
      document.body.appendChild(this.modal.nativeElement);
    });

    this.modal.nativeElement.addEventListener('hidden.bs.modal', () => {
      originalParent.appendChild(this.modal.nativeElement);
      this.form.reset()
      this.form.markAsUntouched()
      this.subscriberSearch = null
    });
  }

  submit = () => {
    if (this.form.valid && this.subscriberSearch) {
      this.subscriberSearch.id = this.id.value
      this.subscriberSearch.name = this.name.value

      if (this.id.value) {
        this.storeNgrx.dispatch(SubscriberSearchActions.updateSubscriberSearch({subscriberSearch: this.subscriberSearch}))
      } else {
        this.storeNgrx.dispatch(SubscriberSearchActions.storeSubscriberSearch({subscriberSearch: this.subscriberSearch}))
      }
    }

    this.ref.markForCheck()
    this.form.markAllAsTouched()
  }

  get id(): AbstractControl {
    return this.form.get('id')!;
  }

  get name(): AbstractControl {
    return this.form.get('name')!;
  }

  ngOnDestroy(): void {
    if (this.modalLoad) {
      this.modalLoad.hide()
    }

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  delete() {
    if (this.subscriberSearch) {
      this.storeNgrx.dispatch(SubscriberSearchActions.deleteSubscriberSearch({subscriberSearch: this.subscriberSearch}))
    }
  }
}
