import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import {faCircleXmark} from "@fortawesome/pro-regular-svg-icons";
import {faGripDotsVertical, faCircleSmall} from "@fortawesome/pro-solid-svg-icons";
import {Subject, Subscription, takeUntil} from "rxjs";
import {DragulaService} from "ng2-dragula";
import {SkillModel} from "../../../core/models/skill.model";

@Component({
  selector: 'app-form-skill',
  templateUrl: './form-skill.component.html',
  styleUrls: ['./form-skill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormSkillComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: FormSkillComponent,
    },
  ],
})
export class FormSkillComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {

  fa = {faCircleXmark, faGripDotsVertical, faCircleSmall}
  @Input()
  formControl!: FormControl;

  @Input()
  skillsItems: any[]

  @Input()
  isFavorite: boolean = false

  @Input()
  withLevel: boolean = true

  @Input()
  draggable: boolean = false

  @Input()
  max: number = 20

  @Input()
  class: string = ''

  @Input()
  displaySkills: boolean = true

  @Input()
  displayPreview: boolean = true

  @Input()
  canAddSkill: boolean = true

  @ViewChild('tmpSelectedSkill') tmpSelectedSkill!: ElementRef;

  subsDragula = new Subscription();

  skillsFavorite: any[] = []
  skillsAdd: any[] = []

  ngUnsubscribe = new Subject<void>();
  constructor(private ref: ChangeDetectorRef, private dragulaService: DragulaService) {

    this.subsDragula.add(this.dragulaService.drop("SKILLS").pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ name, el, target, source, sibling }) => {
        this.formControl.patchValue([...this.skillsFavorite, ...this.skillsAdd])
      })
    );

  }
  public ngOnInit(): void {

    // // @ts-ignore
    // if (this.formControl['_markAsTouched'] === undefined) {
    // }
    // @ts-ignore
    this.formControl['_markAsTouched'] = this.formControl.markAsTouched;
    this.formControl.markAsTouched = () => {
      // @ts-ignore
      this.formControl['_markAsTouched']();
      this.formControl.parent?.markAsTouched()
      // this.formControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.ref.markForCheck()
    }

    this.skillsFavorite = this.skills.value.filter((skill: any) => skill.is_favorite)
    this.skillsAdd = this.skills.value.filter((skill: any) => !skill.is_favorite)
    this.skillsFavorite = this.skillsFavorite.sort((skill1, skill2) => {
      return skill1.order - skill2.order
    })
  }

  public onTouched: () => void = () => {};

  public writeValue(v: any) {
    this.ref.markForCheck()
  }

  public registerOnChange(fn: (v: any) => void) {
    // this.onChange = fn
  }

  public setDisabledState(disabled: boolean) {
    disabled ? this.formControl.disable() : this.formControl.enable();
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  public validate(value: any): ValidationErrors | null {

    if (this.isFavorite === false) {
      return null
    }
    if (value.value.length !== 0 && this.isFavorite) {
      return null
    }

    this.ref.markForCheck()
    return value.errors;
  }

  addSkill(skill: SkillModel, isFavorite: boolean) {

    let skills = [...this.formControl.value]
    if (skill) {
      let newOrder: number = -1
      if (isFavorite) {
        if (skills.length === 0) {
          newOrder = 0
        } else {
          newOrder = Math.max(...skills.map((skill: any) => skill.order)) + 1;
        }
      }

      let newSkill: SkillModel
      if (!this.withLevel) {
        newSkill = {...skill, is_favorite: isFavorite}
      } else {
        newSkill = {...skill, is_favorite: isFavorite, level:[0, 2]}
      }

      if (newOrder !== -1) {
        newSkill.order = newOrder
      }

      let skillFound = skills.findIndex((skillIndex: any) => skillIndex.title === skill.title)
      if (skillFound === -1) {
        skills.push(newSkill)

        this.skillsFavorite = skills.filter((skill: any) => skill.is_favorite)
        this.skillsAdd = skills.filter((skill: any) => !skill.is_favorite)
        this.skillsFavorite = this.skillsFavorite.sort((skill1, skill2) => {
          return skill1.order - skill2.order
        })

        this.formControl.patchValue([...this.skillsFavorite, ...this.skillsAdd])
        // this.formControl.markAsDirty()
        // this.formControl.markAsTouched()
      }

      if (this.tmpSelectedSkill) {
        // @ts-ignore
        this.tmpSelectedSkill.clearItem(skill)
      }
    }
  }

  setSkillLevel(skill: any, level: number) {
    let skills = [...this.formControl.value]

    let skillFound = skills.findIndex((skillIndex: any) => skillIndex.title === skill.title)
    if (skillFound !== -1) {
      const skillCopy = {...skill};
      skillCopy.level = [level, level + 2];
      skills[skillFound] = skillCopy;
    }

    this.skillsFavorite = skills.filter((skill: any) => skill.is_favorite)
    this.skillsAdd = skills.filter((skill: any) => !skill.is_favorite)
    this.skillsFavorite = this.skillsFavorite.sort((skill1, skill2) => {
      return skill1.order - skill2.order
    })

    this.formControl.patchValue([...this.skillsFavorite, ...this.skillsAdd])
  }

  removeSkill(skill: any) {
    let skills = [...this.formControl.value]

    let skillFound = skills.findIndex((skillIndex: any) => skillIndex.title === skill.title)
    if (skillFound !== -1) {
      skills.splice(skillFound, 1)
    }

    this.skillsFavorite = skills.filter((skill: any) => skill.is_favorite)
    this.skillsAdd = skills.filter((skill: any) => !skill.is_favorite)
    this.skillsFavorite = this.skillsFavorite.sort((skill1, skill2) => {
      return skill1.order - skill2.order
    })

    this.formControl.patchValue([...this.skillsFavorite, ...this.skillsAdd])
    // this.formControl.markAsDirty()
    // this.formControl.markAsTouched()
  }

  countSkills() {
    if (this.isFavorite) {
      return this.formControl.value.filter((skill: any) => skill.is_favorite).length
    } else {
      return this.formControl.value.filter((skill: any) => !skill.is_favorite).length
    }
  }

  get skills(): FormControl {
    return this.formControl!;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
