import {Login} from "../shared/models/Auth";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {tap} from "rxjs";
import {GetSubscriber, Logout, SubscriberStateModel} from "../shared/models/Subscriber";
import {SubscriberService} from "../shared/data-access/subscriber.service";
import {Store as StoreNgrx} from "@ngrx/store";
import * as SubscriptionActions from "../core/actions/subscription.actions";
import * as EProfileActions from "../core/actions/eProfile.actions";
import * as Sentry from "@sentry/angular-ivy";

@State<SubscriberStateModel>({
  name: 'user',
  defaults: {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    status: null,
    approval: null,
    availability: null,
    profile_type: null,
    avatar: null,
    phone: null,
    role: null
  }
})

@Injectable()
export class SubscriberState {

  constructor(private subscriberService: SubscriberService, private storeNgrx: StoreNgrx) {}

  @Selector()
  static isApproval(state: SubscriberStateModel): boolean | null {
    return state.approval;
  }

  @Selector()
  static profileType(state: SubscriberStateModel): string | null {
    return state.profile_type;
  }

  @Action(GetSubscriber)
  login(ctx: StateContext<SubscriberStateModel>, action: Login) {
    return this.subscriberService.get().pipe(
      tap((result: SubscriberStateModel) => {
        if (result.profile_type === 'E') {
          this.storeNgrx.dispatch(EProfileActions.loadMe())
          this.storeNgrx.dispatch(SubscriptionActions.getSubscription())
        }

        if (result && result.email) {
          Sentry.setUser({ email: result.email, profile_type: result.profile_type});
        }
        ctx.patchState(result);
      })
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<SubscriberStateModel>) {
    ctx.setState({
      id: null,
      first_name: null,
      last_name: null,
      email: null,
      status: null,
      approval: null,
      availability: null,
      profile_type: null,
      avatar: null,
      phone: null,
      role: null
    });
  }
}
