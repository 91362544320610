import {createReducer, on} from '@ngrx/store';
import * as CommonActions from '../actions/common.actions';
import {SkillModel} from "../models/skill.model";

export const CommonFeatureKey = 'common';

export interface State {
  skills: SkillModel[],
  loading: boolean,
  error: any
}

export const initialState: State = {
  skills: [],
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(CommonActions.loadSkills, (state) => ({...state, loading: true, error: null})),
  on(CommonActions.loadSkillsSuccess, (state, {data}) => ({
    ...state,
    skills: data,
    loading: false,
    error: null
  })),
  on(CommonActions.loadSkillsFailure, (state, {error}) => ({...state, loading: false, error})),
);


