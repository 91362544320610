import {createAction, props} from '@ngrx/store';
import {EProfileModel} from "../models/eProfile.model";
import {InvoiceModel} from "../models/stripe/invoice.model";
import {PushModel} from "../models/push.model";

export const loadMe = createAction(
  '[EProfile] Load Me'
);

export const loadMeSuccess = createAction(
  '[EProfile] Load Me Success',
  props<{ data: EProfileModel }>()
);

export const loadMeFailure = createAction(
  '[EProfile] Load Me Failure',
  props<{ error: any }>()
);

export const updateSignUp = createAction(
  '[EProfile] Update SignUp',
  props<{ body: any, step: number }>()
);

export const updateSignUpSuccess = createAction(
  '[EProfile] Update SignUp Success',
  props<{ data: EProfileModel }>()
);

export const updateSignUpFailure = createAction(
  '[EProfile] Update SignUp Failure',
  props<{ error: any }>()
);

export const getQuota = createAction(
  '[EProfile] Get Quota'
);

export const getQuotaSuccess = createAction(
  '[EProfile] Get Quota Success',
  props<{ data: any }>()
);

export const getQuotaFailure = createAction(
  '[EProfile] Get Quota Failure',
  props<{ error: any }>()
);

export const updateBillingDetails = createAction(
  '[EProfile] Update Billing Details',
  props<{ name: string, address: string, zip_code: string, city: string, country: string, tva: string }>()
);

export const updateBillingDetailsSuccess = createAction(
  '[EProfile] Update Billing Details Success',
  props<{ data: any }>()
);

export const updateBillingDetailsFailure = createAction(
  '[EProfile] Update Billing Details Failure',
  props<{ error: any }>()
);

export const initPaymentMethod = createAction(
  '[EProfile] Init Payment Method'
);

export const initPaymentMethodSuccess = createAction(
  '[EProfile] Init Payment Method Success',
  props<{ data: any }>()
);

export const initPaymentMethodFailure = createAction(
  '[EProfile] Init Payment Method Failure',
  props<{ error: any }>()
);

export const updatePaymentMethod = createAction(
  '[EProfile] Update Method Payment',
  props<{ pmId: string }>()
);

export const updatePaymentMethodSuccess = createAction(
  '[EProfile] Update Payment Method Success',
  props<{ data: any }>()
);

export const updatePaymentMethodFailure = createAction(
  '[EProfile] Update Payment Method Failure',
  props<{ error: any }>()
);

export const getInvoices = createAction(
  '[EProfile] Get Invoices'
);

export const getInvoicesSuccess = createAction(
  '[EProfile] Get Invoices Success',
  props<{ data: InvoiceModel[] }>()
);

export const getInvoicesFailure = createAction(
  '[EProfile] Get Invoices Failure',
  props<{ error: any }>()
);

export const sendNotificationForContact = createAction(
  '[EProfile] Send notification for contact'
);

export const sendNotificationForContactSuccess = createAction(
  '[EProfile] Send notification for contact Success'
);

export const sendNotificationForContactFailure = createAction(
  '[EProfile] Send notification for contact Failure',
  props<{ error: any }>()
);

export const sendNotificationForShortList = createAction(
  '[EProfile] Send notification for short list'
);

export const sendNotificationForShortListSuccess = createAction(
  '[EProfile] Send notification for short list Success'
);

export const sendNotificationForShortListFailure = createAction(
  '[EProfile] Send notification for short list Failure',
  props<{ error: any }>()
);

export const getPushes = createAction(
  '[EProfile] Get pushes'
);

export const getPushesSuccess = createAction(
  '[EProfile] Get pushes Success',
  props<{ data: PushModel[] }>()
);

export const getPushesFailure = createAction(
  '[EProfile] Get pushes Failure',
  props<{ error: any }>()
);

//TODO: a supprimer
export const updateBusiness = createAction(
  '[EProfile] Update Business',
  props<{ business: number }>()
);

export const updateBusinessSuccess = createAction(
  '[EProfile] Update Business Success',
  props<{ data: any }>()
);

export const updateBusinessFailure = createAction(
  '[EProfile] Update Business Failure',
  props<{ error: any }>()
);

export const updateBusinessSelected = createAction(
  '[EProfile] Update Business Selected',
  props<{ business: number }>()
);

export const addDay = createAction(
  '[EProfile] Add day'
);

export const addDaySuccess = createAction(
  '[EProfile] Add day Success',
  props<{ data: any }>()
);

export const addDayFailure = createAction(
  '[EProfile] Add day Failure',
  props<{ error: any }>()
);
