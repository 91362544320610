import {ApplicationRef, Injectable, OnDestroy} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {filter, Subject, takeUntil} from 'rxjs';
import {NavigationStart, Router} from "@angular/router";

@Injectable()
export class CheckForUpdateService implements OnDestroy{

  ngUnsubscribe = new Subject<void>();

  constructor(appRef: ApplicationRef, updates: SwUpdate, private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationStart))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async () => {
        try {
          const updateFound = await updates.checkForUpdate();
        } catch (err) {
        }
      })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
