import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromPixelSearchMatchReducer from '../reducers/pixelSearchMatch.reducer';

export const getPixelSearchMatchState = createFeatureSelector<fromPixelSearchMatchReducer.State>('pixelSearchMatch');

export const loading = createSelector(getPixelSearchMatchState, (state: fromPixelSearchMatchReducer.State) => state.loading);

export const selectError = createSelector(getPixelSearchMatchState, (state: fromPixelSearchMatchReducer.State) => state.error);

export const selectHistory = createSelector(getPixelSearchMatchState, (state: fromPixelSearchMatchReducer.State) => state.history);
