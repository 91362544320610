<ul class="nav nav-pills nav-flush d-flex flex-column gap-2 mt-4">
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/account/personal" [ariaCurrentWhenActive]="'page'" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'"
       [routerLinkActive]="'active'">
      <fa-icon [icon]="fa.faUser"></fa-icon>
      <div class="d-none d-lg-block">Informations personnelles</div>
      <div class="d-lg-none">Informations personnelles</div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/account/tools" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'"
       [routerLinkActive]="'active'">
      <fa-icon [icon]="fa.faPuzzlePiece"></fa-icon>
      <div class="d-flex justify-content-between flex-fill align-items-center">
        <div class="d-none d-lg-block">Mes outils connectés</div>
        <div class="d-lg-none">Mes outils connectés</div>
        <div class="badge" *ngIf="!toolsVisited">&nbsp;</div>
      </div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/account/alerts" [routerLinkActive]="'active'" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'">
      <fa-icon [icon]="fa.faSquareExclamation"></fa-icon>
      <div class="d-none d-lg-block">Gestion des alertes</div>
      <div class="d-lg-none">Gestion des alertes</div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/account/notifications" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'"
       [routerLinkActive]="'active'">
      <fa-icon [icon]="fa.faBell"></fa-icon>
      <div class="d-none d-lg-block">Gestion des Notifications</div>
      <div class="d-lg-none">Gestion des Notifications</div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/account/templates" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'"
       [routerLinkActive]="'active'">
      <fa-icon [icon]="fa.faPenNib"></fa-icon>
      <div class="d-none d-lg-block">Gestion des templates</div>
      <div class="d-lg-none">Gestion des templates</div>
    </a>
  </li>
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'">
      <fa-icon [icon]="fa.faCircleQuestion"></fa-icon>
      <a class="d-none d-lg-block" [href]="('' | environment).PUBLIC_URL + 'faq'" target="_blank">Foire aux questions</a>
      <a class="d-lg-none" [href]="('' | environment).PUBLIC_URL + 'faq'" target="_blank">Foire aux questions</a>
    </a>
  </li>
</ul>
<hr class="p-1">

<ul class="nav nav-pills nav-flush d-flex flex-column gap-2">
  <li class="nav-item flex-fill">
    <a class="nav-link d-flex gap-2" routerLink="/logout">
      <fa-icon [icon]="fa.faRightFromBracket"></fa-icon>
      <div>Déconnexion</div>
    </a>
  </li>
</ul>
