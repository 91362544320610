import {ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {SubscriberState} from "../../../state/subscriber-state.service";
import {Store} from "@ngxs/store";
import {Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {AuthState} from "../../../state/auth.state";

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectComponent implements OnDestroy {
  ngUnsubscribe = new Subject<void>();

  constructor(private store: Store, private router: Router) {
    this.store.select(SubscriberState.profileType).pipe(takeUntil(this.ngUnsubscribe)).subscribe((profile_type) => {
        if (profile_type == 'E') {
          this.router.navigate(['/recruitment'], {replaceUrl: true})
        } else if (profile_type == 'C') {
          this.router.navigate(['/dashboard'], {replaceUrl: true})
        } else if (!this.store.selectSnapshot(AuthState.token)) {
          this.router.navigate(['/login'], {replaceUrl: true})
        }
    })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
