import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import {GoogleMapService} from "../../services/GoogleMapService";
import {Subject, Subscription, takeUntil} from "rxjs";
import {faSearch} from "@fortawesome/pro-light-svg-icons";
import {faCircleXmark} from "@fortawesome/pro-regular-svg-icons";
import {LabelType, Options} from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-form-address',
  templateUrl: './form-address.component.html',
  styleUrls: ['./form-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  providers: [GoogleMapService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormAddressComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: FormAddressComponent,
    }],
})
export class FormAddressComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor, Validator  {

  fa = {faCircleXmark, faSearch}

  @Input()
  form: FormGroup

  @Input()
  formErrors: any

  @Input()
  type: string = 'zipCode'

  @Input()
  displayResult: boolean = true

  @ViewChild('addressText')
  addressText!: ElementRef;

  @Input()
  displayPreview: boolean = true

  placeholder: string = 'Recherche par code postal'
  protected placeSubscription: Subscription;

  options: any = {
    componentRestrictions: { country: 'FR' }
  }

  optionsPerimeter: Options = {
    floor: 5,
    ceil: 25,
    draggableRangeOnly: true,
    tickStep: 5,
    step: 5,
    showTicksValues: true,
    tickValueStep: 5,
    translate: (value: number, label: LabelType): string => {
      return ''
    },
    getLegend: (value: number): string => {
      if (value >= 25) {
        if (value == this.perimeter.value) {
          return '<span class="color-primary">+ 25</span>';
        }
        return '+25';
      } else {
        if (value == this.perimeter.value) {
          return '<span class="color-primary">' + value + '</span>';
        }
      }
      return value + '';
    }
  };

  ngUnsubscribe = new Subject<void>();

  constructor(private googleMapService : GoogleMapService, private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.type === 'zipCode') {
      this.placeholder = 'Recherche par code postal'
    } else if (this.type === 'city' || this.type === 'city_perimeter') {
      this.placeholder = 'Recherche par ville'
    } else {
      this.placeholder = 'Recherche par adresse'
    }

    // @ts-ignore
    this.form.get('city')!['_markAsTouched'] = this.form.get('city')!.markAsTouched;
    this.form.get('city')!.markAsTouched = () => {
      // @ts-ignore
      this.form.get('city')!['_markAsTouched']();
      // this.formControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.ref.markForCheck()
    }

    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.ref.markForCheck()
    })
  }

  ngAfterViewInit(): void {
    let types: string[]
    if (this.type === 'zipCode') {
      types = ['postal_code']
    } else if (this.type === 'city' || this.type === 'city_perimeter') {
      types = ['locality']
    } else {
      types = ['address']
    }
    this.options.types = types
  }

  public onTouched: () => void = () => {};

  public writeValue(v: any) {
    this.ref.markForCheck()
  }

  public setDisabledState(disabled: boolean) {
    disabled ? this.form.disable() : this.form.enable();
  }

  registerOnChange(fn: any): void {
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  public validate(value: any): ValidationErrors | null {

    // if (this.isFavorite === false) {
    //   return null
    // }
    // if (value.value.length !== 0 && this.isFavorite) {
    //   return null
    // }

    this.ref.markForCheck()
    return value.errors;
  }

  onAddressChange(place: any): void {
    const zipCode = this.googleMapService.getPostCode(place)
    const city = this.googleMapService.getCity(place)
    const country = this.googleMapService.getCountry(place)
    let address = ''
    if (this.type !== 'zipCode') {
      const streetNumber = this.googleMapService.getStreetNumber(place)

      if (streetNumber !== null) {
        address = streetNumber + ' '
      }
      let street = this.googleMapService.getStreet(place)
      if (street) {
        address += street
      }
    }
    if (address.length === 0) {
      this.form.patchValue({
        zip_code: zipCode,
        city: city,
        country: country,
      })
    } else {
      this.form.patchValue({
        zip_code: zipCode,
        city: city,
        country: country,
        address: address
      })
    }
    this.addressText.nativeElement.value = ''
    this.city.markAsTouched()
    this.ref.markForCheck()
  }

  remove() {
    this.form.patchValue({
      zip_code: '',
      city: '',
      country: '',
      address: null
    })
  }

  get zipCode(): AbstractControl {
    return this.form.get('zip_code')!;
  }
  get city(): AbstractControl {
    return this.form.get('city')!;
  }
  get country(): AbstractControl {
    return this.form.get('country')!;
  }

  get address(): AbstractControl {
    return this.form.get('address')!;
  }

  get perimeter(): AbstractControl {
    return this.form.get('perimeter')!;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formErrors']) {
      for (let error in this.formErrors) {
        this.form.get(error)?.setErrors({serverError: this.formErrors[error]}, {emitEvent: true})
      }
      this.ref.markForCheck()
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
