import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnInit {

  @Input()
  subscriber: any = null

  @Input()
  eProfile: any = null

  @Input()
  user: any = null

  @Input()
  size: string = 'md'

  @Input()
  badge: string|null = null

  @Input()
  target: string = 'menu'

  constructor() {}

  ngOnInit() {
  }
}
