import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {Store} from "@ngxs/store";
import {Subject, takeUntil} from "rxjs";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {GetSubscriber} from "../../models/Subscriber";
import {faCircle} from "@fortawesome/pro-solid-svg-icons";
import {SubscriberService} from "../../data-access/subscriber.service";

declare var bootstrap: any

@Component({
  selector: 'app-modal-availability-candidate',
  templateUrl: './modal-availability-candidate.component.html',
  styleUrls: ['./modal-availability-candidate.component.scss']
})
export class ModalAvailabilityCandidateComponent implements OnInit, AfterViewInit, OnDestroy {

  fa = {faCircle}

  @Input('refreshParent')
  refreshParent: any

  @Input('backdrop')
  backdrop: boolean = true

  @ViewChild('modal')
  modal: ElementRef

  formRefuse = new FormGroup({
    reason: new FormControl(null, Validators.required),
    comment: new FormControl(null),
  })

  displayOtherReason: boolean = false

  reasons = [
    {key: 0, title: 'J’ai trouvé un poste avec FT'}, {key: -1, title: 'J’ai trouvé un poste en parallèle'},
    {key: 2, title: 'J’ai trop d’offres'}, {key: 3, title: 'Je suis indisponible quelques temps'},
    {key: 4, title: 'Je reste dans mon entreprise actuelle'}, {key: -2, title: 'Autres'}]

  loading: boolean = false

  modalLoad: any

  ngUnsubscribe = new Subject<void>();

  constructor(private ref: ChangeDetectorRef, private subscriberService: SubscriberService, private store: Store) {}

  show(): void {
    this.modalLoad.show()
  }

  hide() {
    this.modalLoad.hide()
  }

  ngOnInit(): void {
    this.reason.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      console.log(value)
      if (value == -1 || value == -2) {
        this.displayOtherReason = true
        if (value == -2) {
          this.comment.setValidators([Validators.required])
        }
      } else {
        this.comment.clearValidators()
        this.comment.updateValueAndValidity()
        this.displayOtherReason = false
      }
      this.ref.markForCheck()
    })
  }

  ngAfterViewInit(): void {
    this.modalLoad = new bootstrap.Modal(this.modal.nativeElement)

    let originalParent = this.modal.nativeElement.parentElement;

    this.modal.nativeElement.addEventListener('show.bs.modal', () => {
      document.body.appendChild(this.modal.nativeElement);
    });

    this.modal.nativeElement.addEventListener('hidden.bs.modal', () => {
      originalParent.appendChild(this.modal.nativeElement);
    });
  }

  submit = () => {
    let reason = this.reasons.find((reason) => {
      return reason.key ===  this.reason.value
    })

    if (this.formRefuse.valid && reason) {

      this.loading = true
      this.subscriberService.unavailable(reason.title, this.comment.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => {
          this.hide()
          this.store.dispatch(new GetSubscriber())
          this.formRefuse.reset()
        },
        complete: () => {
          this.loading = false
        }
      })
    }
    this.formRefuse.markAllAsTouched()
    this.ref.markForCheck()
  }

  get reason(): AbstractControl {
    return this.formRefuse.get('reason')!;
  }

  get comment(): AbstractControl {
    return this.formRefuse.get('comment')!;
  }

  ngOnDestroy(): void {

    if (this.modalLoad) {
      this.modalLoad.hide()
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
