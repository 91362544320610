<form [formGroup]="form">
  <div class="input-group flex-nowrap search" [ngClass]="{'is-invalid' : (school.dirty || school.touched) && school.errors}">
    <span class="input-group-text"><fa-icon [icon]="fa.faSearch"></fa-icon></span>
    <input type="text" class="form-control" formControlName="school"
           placeholder="Recherche">
  </div>
  <div *ngIf="school?.errors" class="invalid-feedback">
    <div *ngIf="school?.hasError('required')">
      Ce champ est obligatoire
    </div>
    <div *ngIf="school?.errors?.['serverError']">
      {{school.errors?.['serverError']}}
    </div>
  </div>
</form>

