<ng-select id="jobs" [items]="departments$ | async" #tmpSelectedJob
           bindLabel="title"
           (change)="addJob($event)"
           bindValue="id"
           appendTo="body"
           [closeOnSelect]="true"
           [multiple]="false"
           [clearable]="false"
           groupBy="jobs"
           placeholder="Sélectionner"
           [ngClass]="(formControl.dirty || formControl.touched) && formControl.errors ? 'is-invalid' : ''">
  <ng-template ng-optgroup-tmp let-item="item">
    <span class="text-muted">{{item.title}}</span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    {{item.title}} {{item.disabled}}
  </ng-template>
</ng-select>

<div class="d-flex mt-3 flex-wrap gap-2" *ngIf="displayPreview && formControl.value?.length !== 0">
  <div *ngFor="let job of formControl.value" class="tag tag-secondary selected pointer" (click)="removeJob(job)">
    {{job.title}} <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
  </div>
</div>
