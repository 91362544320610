import {NgModule} from '@angular/core';
import {FormContentComponent} from "./form-content.component";
import {SharedModule} from "../../shared.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@NgModule({
  imports: [SharedModule, FontAwesomeModule],
  declarations: [FormContentComponent],
  exports: [FormContentComponent],
})
export class FormContentModule {}
