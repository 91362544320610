import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromSearchReducer from '../reducers/search.reducer';

export const getSearchState = createFeatureSelector<fromSearchReducer.State>('search');

export const selectSearchInProgress = createSelector(getSearchState, (state: fromSearchReducer.State) => state.searchInProgress);

export const selectError = createSelector(getSearchState, (state: fromSearchReducer.State) => state.error);

export const selectResult = createSelector(getSearchState, (state: fromSearchReducer.State) => state.result);

export const selectMeta = createSelector(getSearchState, (state: fromSearchReducer.State) => state.meta);

export const selectFilter = createSelector(getSearchState, (state: fromSearchReducer.State) => state.filter);

export const selectSort = createSelector(getSearchState, (state: fromSearchReducer.State) => state.sort);
