import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";


const API_URL = environment.API_URL;
const PATH = '/'

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) {
  }

  skills() {
    return this.http.get<any>(API_URL + PATH + 'skill/all/')
  }
}
