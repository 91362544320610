import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

const API_URL = environment.API_URL;
const PATH = '/eprofile/'

@Injectable({
  providedIn: 'root'
})
export class EProfileService {

  constructor(protected http: HttpClient) { }

  get() {
    return this.http.get<any>(API_URL + PATH + 'me')
  }

  update(body: any) {
    return this.http.patch<any>(API_URL + PATH, body)
  }

  setFavorite(id: string) {
    return this.http.patch<any>(API_URL + PATH + 'favorite/' + id, {})
  }
}
