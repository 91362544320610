import {NgModule} from '@angular/core';
import {FormAddressComponent} from "./form-address.component";
import {SharedModule} from "../../shared.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {NgxSliderModule} from "@angular-slider/ngx-slider";

@NgModule({
  imports: [SharedModule, FontAwesomeModule, GooglePlaceModule, NgxSliderModule],
  declarations: [FormAddressComponent],
  exports: [FormAddressComponent],
})
export class FormAddressModule {}
