import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromPixelSearchReducer from '../reducers/pixelSearch.reducer';

export const getPixelSearchState = createFeatureSelector<fromPixelSearchReducer.State>('pixelSearch');

export const selectLoading = createSelector(getPixelSearchState, (state: fromPixelSearchReducer.State) => state.loading);

export const selectLoadingEstimation = createSelector(getPixelSearchState, (state: fromPixelSearchReducer.State) => state.loadingEstimation);

export const selectError = createSelector(getPixelSearchState, (state: fromPixelSearchReducer.State) => state.error);

export const selectCurrent = createSelector(getPixelSearchState, (state: fromPixelSearchReducer.State) => state.current);

export const selectAll = createSelector(getPixelSearchState, (state: fromPixelSearchReducer.State) => state.all);
