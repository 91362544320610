import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {
  faBriefcase,
  faCommentLines,
  faFileMagnifyingGlass,
  faHandshakeSimple,
  faIdCard,
  faLightbulbOn,
  faPaperclip
} from "@fortawesome/pro-light-svg-icons";
import {Router} from "@angular/router";

@Component({
  selector: 'app-profile-candidate-menu',
  templateUrl: './profile-candidate-menu.component.html',
  styleUrls: ['./profile-candidate-menu.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileCandidateMenuComponent implements OnInit {

  fa = {faHandshakeSimple, faPaperclip, faCommentLines, faBriefcase, faLightbulbOn, faFileMagnifyingGlass, faIdCard}

  @Input()
  subscriber: any = null

  @Input()
  offcanvas: boolean = false

  constructor(private router: Router) {
  }

  ngOnInit() {
  }
}
