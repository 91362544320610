import {NgModule} from '@angular/core';
import {FormRemoteComponent} from "./form-remote.component";
import {SharedModule} from "../../shared.module";
import {NgxSliderModule} from "@angular-slider/ngx-slider";

@NgModule({
  imports: [SharedModule, NgxSliderModule],
  declarations: [FormRemoteComponent],
  exports: [FormRemoteComponent],
})
export class FormRemoteModule {}
