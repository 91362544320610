import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromSubscriptionReducer from '../reducers/subscription.reducer';

export const getSubscriptionState = createFeatureSelector<fromSubscriptionReducer.State>('subscription');

export const selectLoading = createSelector(getSubscriptionState, (state: fromSubscriptionReducer.State) => state.loading);

export const selectError = createSelector(getSubscriptionState, (state: fromSubscriptionReducer.State) => state.error);

export const selectCurrent = createSelector(getSubscriptionState, (state: fromSubscriptionReducer.State) => state.current);

export const selectPrices = createSelector(getSubscriptionState, (state: fromSubscriptionReducer.State) => state.prices);
