import {createReducer, on} from '@ngrx/store';
import * as SubscriptionActions from '../actions/subscription.actions';
import {SubscriptionModel} from "../models/subscription.model";
import {PriceModel} from "../models/stripe/price.model";

export const SearchFeatureKey = 'subscription';

export interface State {
  current: SubscriptionModel | null
  prices: PriceModel[]
  loading: boolean
  error: any
}

export const initialState: State = {
  current: null,
  prices: [],
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(SubscriptionActions.getSubscription, (state) => ({...state, loading: true, error: null})),
  on(SubscriptionActions.getSubscriptionSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(SubscriptionActions.getSubscriptionFailure, (state, {error}) => ({...state, loading: false, error})),
  on(SubscriptionActions.getSubscriptionPrices, (state) => ({...state, loading: true, error: null})),
  on(SubscriptionActions.getSubscriptionPricesSuccess, (state, {data}) => ({
    ...state,
    prices: [...data],
    loading: false,
    error: null
  })),
  on(SubscriptionActions.getSubscriptionPricesFailure, (state, {error}) => ({...state, loading: false, error})),
  on(SubscriptionActions.updateSubscription, (state) => ({...state, loading: true, error: null})),
  on(SubscriptionActions.updateSubscriptionSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(SubscriptionActions.updateSubscriptionFailure, (state, {error}) => ({...state, loading: false, error})),
  on(SubscriptionActions.updateSubscriptionCancel, (state) => ({...state, loading: true, error: null})),
  on(SubscriptionActions.updateSubscriptionCancelSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(SubscriptionActions.updateSubscriptionCancelFailure, (state, {error}) => ({...state, loading: false, error})),
);
