import {NgModule} from '@angular/core';
import {FormYearExperienceComponent} from "./form-year-experience.component";
import {SharedModule} from "../../shared.module";
import {NgxSliderModule} from "@angular-slider/ngx-slider";

@NgModule({
  imports: [SharedModule, NgxSliderModule],
  declarations: [FormYearExperienceComponent],
  exports: [FormYearExperienceComponent],
})
export class FormYearExperienceModule {}
