import {createAction, props} from '@ngrx/store';
import {CProfileModel} from "../models/cProfile.model";

export const loadMe = createAction(
  '[CProfile] Load Me'
);

export const loadMeSuccess = createAction(
  '[CProfile] Load Me Success',
  props<{ data: CProfileModel }>()
);

export const loadMeFailure = createAction(
  '[CProfile] Load Me Failure',
  props<{ error: any }>()
);

export const getCProfile = createAction(
  '[CProfile] Get CProfile',
  props<{ id: string }>()
);

export const getCProfileSuccess = createAction(
  '[CProfile] Get CProfile Success',
  props<{ data: any }>()
);

export const getCProfileFailure = createAction(
  '[CProfile] Get CProfile Failure',
  props<{ error: any }>()
);

export const updateCProfileFavorite = createAction(
  '[CProfile] Update CProfile Favorite',
  props<{ cProfileId: string }>()
);

export const updateCProfileFavoriteSuccess = createAction(
  '[CProfile] Update CProfile Favorite Success',
  props<{ data: CProfileModel }>()
);

export const updateCProfileFavoriteFailure = createAction(
  '[CProfile] Update CProfile Favorite Failure',
  props<{ error: any }>()
);

export const updateCProfileRejected = createAction(
  '[CProfile] Update CProfile Rejected',
  props<{ id: string }>()
);

export const updateCProfileRejectedSuccess = createAction(
  '[CProfile] Update CProfile Rejected Success',
  props<{ data: CProfileModel }>()
);

export const updateCProfileRejectedFailure = createAction(
  '[CProfile] Update CProfile Rejected Failure',
  props<{ error: any }>()
);

export const getCProfileGuest = createAction(
  '[CProfile] Get CProfile Guest',
  props<{ id: string }>()
);

export const getCProfileGuestSuccess = createAction(
  '[CProfile] Get CProfile Guest Success',
  props<{ cProfile: CProfileModel }>()
);

export const getCProfileGuestFailure = createAction(
  '[CProfile] Get CProfile Guest Failure',
  props<{ error: any }>()
);
