import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../../environments/environment";


const API_URL = environment.API_URL;
const PATH = '/eprofile/'

@Injectable({
  providedIn: 'root'
})
export class EProfileService {

  constructor(private http: HttpClient) {
  }

  me() {
    return this.http.get<any>(API_URL + PATH + 'me')
  }

  signUp(body: any, step: number) {
    return this.http.post<any>(API_URL + PATH + 'signup/' + step, body)
  }

  quota() {
    return this.http.get<any>(API_URL + PATH + 'me/quota')
  }

  getPushes() {
    return this.http.get<any>(API_URL + PATH + 'me/pushes')
  }

  sendNotificationForContact(entrepriseName: string) {
    const url = 'https://hooks.slack.com/services/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';
    const message = {
      "type": "rich_text",
      "blocks": [
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "Un client Pixel souhaite être contacté pour discuter de l'offre Standard",
          }
        },
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "Ce client est: " + entrepriseName
          }
        },
      ]
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(url, message, {headers: headers, responseType: 'text' })
  }

  sendNotificationForShortList(entrepriseName: string) {
    const url = 'https://hooks.slack.com/services/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';
    const message = {
      "type": "rich_text",
      "blocks": [
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "Un client souhaite avoir une short list",
          }
        },
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "Ce client est: " + entrepriseName
          }
        },
      ]
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(url, message, {headers: headers, responseType: 'text' })
  }

  //todo: supprimer
  updateBusiness(business: number) {
    return this.http.patch<any>(API_URL + PATH + 'business', {business: business})
  }

  addDay() {
    return this.http.patch<any>(API_URL + PATH + 'addDay', {})
  }

}
