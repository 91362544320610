import {AfterViewChecked, ChangeDetectorRef, Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appScrollToError]'
})
export class ScrollToErrorDirective implements AfterViewChecked {

  checked: boolean = false

  clicked: any = null

  constructor(private el: ElementRef, private ref: ChangeDetectorRef) {
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    this.clicked = event.target
  }

  ngAfterViewChecked(): void {
    if (this.el.nativeElement && this.clicked !== null) {
      let form = this.clicked.closest('form')
      let invalid
      if (form) {
        invalid = form.querySelector('.is-invalid')
      } else {
        invalid = document.querySelector('.is-invalid')
      }

      if (invalid) {
        invalid.scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.clicked = null
      }
    }
  }
}
