import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {EProfileModel} from "../../core/models/eProfile.model";
import {Store as StoreNgrx} from "@ngrx/store";
import * as EProfileSelectors from '../../core/selectors/eProfile.selectors';
import {Subject, takeUntil} from "rxjs";
import {SubscriptionModel} from "../../core/models/subscription.model";

@Pipe({
  name: 'canSearch'
})
export class CanSearchPipe implements PipeTransform, OnDestroy {

  business$ = this.storeNgrx.select(EProfileSelectors.selectBusiness)

  ngUnsubscribe = new Subject<void>();

  constructor(private storeNgrx: StoreNgrx) {
  }

  transform(content: any): {
    canSearch: boolean,
    hasJobOffer: boolean,
    hasAddress: boolean,
    hasBillingDetails: boolean,
    hasPaymentMethod: boolean,
    hasSubscription: boolean
  } {
    let hasJobOffer = true
    let hasAddress = false
    let hasPaymentMethod = false
    let hasBillingDetails = false
    let hasSubscription = false

    // if (content.jobOffers?.length !== 0 && content.jobOffers.filter((jobOffer: JobOfferModel) => jobOffer.status === 0).length !== 0) {
    //   hasJobOffer = true
    // }

    let canSearch = false

    this.business$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((business) => {
      if (this.addressFilled(content.eProfile)) {
        hasAddress = true
      }

      if (business === 1) {
        canSearch = hasJobOffer && hasAddress;
      } else {

        if (this.billingDetailsFilled(content.eProfile)) {
          hasBillingDetails = true
        }

        if (this.paymentMethodFilled(content.eProfile)) {
          hasPaymentMethod = true
        }

        if (this.hasSubscriptionActive(content.subscription)) {
          hasSubscription = true
        }

          canSearch = hasJobOffer && hasAddress && hasBillingDetails && hasPaymentMethod && hasSubscription;
      }
    })

    return {canSearch, hasJobOffer, hasAddress, hasBillingDetails, hasPaymentMethod, hasSubscription}
  }

  addressFilled(eProfile: EProfileModel): boolean {
    return !!(eProfile && eProfile.address && eProfile.city && eProfile.zip_code && eProfile.country);
  }

  billingDetailsFilled(eProfile: EProfileModel) {
    return !!(eProfile && eProfile.billing_details && eProfile.billing_details.address && eProfile.billing_details.city
      && eProfile.billing_details.postal_code && eProfile.billing_details.country && eProfile.billing_details.tax && eProfile.billing_details.name);
  }

  paymentMethodFilled(eProfile: EProfileModel) {
    return !!(eProfile && eProfile.pm_type && eProfile.pm_last_four);
  }

  hasSubscriptionActive(subscription: SubscriptionModel) {
    return (subscription && subscription.status === 'active');
  }


  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
