import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {
  faBars,
  faCircleQuestion,
  faGift,
  faRightFromBracket,
  faSliders,
  faUser
} from "@fortawesome/pro-light-svg-icons";
import {Router} from "@angular/router";

@Component({
  selector: 'app-account-candidate-menu',
  templateUrl: './account-candidate-menu.component.html',
  styleUrls: ['./account-candidate-menu.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountCandidateMenuComponent implements OnInit {

  fa = {faUser, faGift, faSliders, faCircleQuestion, faBars, faRightFromBracket}

  @Input()
  subscriber: any = null

  @Input()
  offcanvas: boolean = false

  constructor(private router: Router) {}

  ngOnInit() {
  }
}
