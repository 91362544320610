import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

const API_URL = environment.API_URL;
const PATH = '/payment/'

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  index() {
    return this.http.get<any>(API_URL + PATH + 'index/')
  }

  update(pmId: string) {
    return this.http.post<any>(API_URL + PATH + 'update/', {pm_id: pmId})
  }

  updateBillingDetails(name: string, address: string, zip_code: string, city: string, country: string, tva: string) {
    return this.http.post<any>(API_URL + PATH + 'billingDetails/', {enterprise: name, address, zip_code, city, country, tva})
  }

  invoices() {
    return this.http.get<any>(API_URL + PATH + 'invoices/')
  }
}
