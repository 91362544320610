import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromCProfileReducer from '../reducers/cProfile.reducer';

export const getCProfileState = createFeatureSelector<fromCProfileReducer.State>('cProfile');

export const loading = createSelector(getCProfileState, (state: fromCProfileReducer.State) => state.loading);

export const selectError = createSelector(getCProfileState, (state: fromCProfileReducer.State) => state.error);

export const selectMe = createSelector(getCProfileState, (state: fromCProfileReducer.State) => state.me);

export const selectCurrent = createSelector(getCProfileState, (state: fromCProfileReducer.State) => state.current);
