import {NgModule} from '@angular/core';
import {AccountEnterpriseMenuComponent} from "./account-enterprise-menu.component";
import {CommonModule} from "@angular/common";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {RouterModule} from "@angular/router";
import {StatusCandidateModule} from "../../../shared/ui/status-candidate/status-candidate.module";
import {SharedModule} from "../../../shared/shared.module";

@NgModule({
    imports: [CommonModule, FontAwesomeModule, RouterModule, StatusCandidateModule, SharedModule],
  declarations: [AccountEnterpriseMenuComponent],
  exports: [AccountEnterpriseMenuComponent],
})
export class AccountEnterpriseMenuModule {}
