<div class="dropdown" *ngIf="subscriber.status == 1">
  <button class="btn dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    <div class="d-flex flex-column gap-1">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center gap-2">
          <div class="">
            <ng-container [ngSwitch]="subscriber.availability">
              <ng-container *ngSwitchCase="1">
                <fa-icon [icon]="fa.faCircle" style="color: #F14848"></fa-icon>
              </ng-container>
              <ng-container *ngSwitchCase="2">
                <fa-icon [icon]="fa.faCircle" class="text-warning"></fa-icon>
              </ng-container>
              <ng-container *ngSwitchCase="0">
                <fa-icon [icon]="fa.faCircle" style="color: #12AA81"></fa-icon>
              </ng-container>
            </ng-container>
          </div>
          <div class="color-tertiary">
            <ng-container [ngSwitch]="subscriber.availability">
              <ng-container *ngSwitchCase="1">
                Indisponible
              </ng-container>
              <ng-container *ngSwitchCase="2">
                En Veille
              </ng-container>
              <ng-container *ngSwitchCase="0">
                Disponible
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="color-tertiary">
          <fa-icon [icon]="fa.faCaretDown"></fa-icon>
        </div>
      </div>
      <div class="text-muted font-size-12 text-wrap text-start">
        <ng-container [ngSwitch]="subscriber.availability">
          <ng-container *ngSwitchCase="1">
            Votre profil est invisible auprès des sociétés
          </ng-container>
          <ng-container *ngSwitchCase="2">
            Votre profil a une visibilité restreinte auprès des sociétés
          </ng-container>
          <ng-container *ngSwitchCase="0">
            Votre profil est visible auprès des sociétés
          </ng-container>
        </ng-container>
      </div>
    </div>
  </button>

  <ul class="dropdown-menu">
    <div class="fw-bold">Définir votre statut</div>
    <hr class="my-2">
    <li class="dropdown-item d-flex flex-column gap-1" [ngClass]="{'selected': subscriber.availability == 0}" (click)="available()">
      <div class="d-flex gap-2 align-items-center">
        <div class="">
          <fa-icon [icon]="fa.faCircle" style="color: #12AA81"></fa-icon>
        </div>
        <div class="color-tertiary">
          Disponible
        </div>
      </div>
      <div class="text-muted font-size-12">
        Votre profil est visible auprès des sociétés.
      </div>
    </li>
    <li class="dropdown-item d-flex flex-column gap-1" (click)="standby()">
      <div class="d-flex gap-2 align-items-center">
        <div class="">
          <fa-icon [icon]="fa.faCircle" class="text-warning"></fa-icon>
        </div>
        <div class="color-tertiary">
          En Veille
        </div>
      </div>
      <div class="text-muted font-size-12">
        Visibilité restreinte au près des sociétés. Vous pouvez échanger avec vos process en cours.
      </div>
    </li>
    <li class="dropdown-item d-flex flex-column gap-1" [ngClass]="{'selected': subscriber.availability == 1}">
      <app-modal-availability-candidate #modalAvailabilityCandidateComponent>
        <div class="d-flex gap-2 align-items-center">
          <div class="">
            <fa-icon [icon]="fa.faCircle" style="color: #F14848"></fa-icon>
          </div>
          <div class="color-tertiary">
            Indisponible
          </div>
        </div>
        <div class="text-muted font-size-12">
          Votre profil est invisible auprès des sociétés
          (vous pourrez toujours échanger avec).
        </div>
      </app-modal-availability-candidate>
    </li>
  </ul>
</div>
