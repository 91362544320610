import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as SubscriberSearchActions from '../actions/subscriberSearch.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from "@ngrx/store";
import {SubscriberSearchService} from "../apis/subscriberSearch.service";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class SubscriberSearchEffects {
  constructor(private actions$: Actions, private subscriberSearchService: SubscriberSearchService, private store: Store,
              private toast: ToastrService) {
  }

  load$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SubscriberSearchActions.loadSearches),
        map((action: any) => action),
        mergeMap(() => {
          return this.subscriberSearchService.all().pipe(
            map(subscriberSearches => SubscriberSearchActions.loadSearchesSuccess({subscriberSearches})),
            catchError(error => of(SubscriberSearchActions.loadSearchesFailure({error})))
          );
        })
      )
    }
  );

  store$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SubscriberSearchActions.storeSubscriberSearch),
        map((action: any) => action),
        mergeMap(({subscriberSearch}) => {
          return this.subscriberSearchService.store(subscriberSearch).pipe(
            map(subscriberSearch => SubscriberSearchActions.storeSubscriberSearchSuccess({subscriberSearch})),
            catchError(error => of(SubscriberSearchActions.storeSubscriberSearchFailure({error})))
          );
        })
      )
    }
  );

  update$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SubscriberSearchActions.updateSubscriberSearch),
        map((action: any) => action),
        mergeMap(({subscriberSearch}) => {
          return this.subscriberSearchService.update(subscriberSearch).pipe(
            map(subscriberSearch => SubscriberSearchActions.updateSubscriberSearchSuccess({subscriberSearch})),
            catchError(error => of(SubscriberSearchActions.updateSubscriberSearchFailure({error})))
          );
        })
      )
    }
  );

  delete$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SubscriberSearchActions.deleteSubscriberSearch),
        map((action: any) => action),
        mergeMap(({subscriberSearch}) => {
          return this.subscriberSearchService.delete(subscriberSearch).pipe(
            map(data => SubscriberSearchActions.deleteSubscriberSearchSuccess({data})),
            catchError(error => of(SubscriberSearchActions.deleteSubscriberSearchFailure({error})))
          );
        })
      )
    }
  );
}
