import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as CommonActions from '../actions/common.actions';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {select, Store} from "@ngrx/store";
import {CommonService} from "../apis/common.service";
import {selectSkills} from "../selectors/common.selectors";

@Injectable()
export class CommonEffects {
  constructor(private actions$: Actions, private commonService: CommonService, private store: Store) {
  }

  loadSkills$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommonActions.loadSkills),
      withLatestFrom(this.store.pipe(select(selectSkills))),
      mergeMap(([action, skills]) => {
        if (skills && skills.length > 0) {
          return of({ type: 'NO_ACTION' });
        } else {
          return this.commonService.skills().pipe(
            map(data => CommonActions.loadSkillsSuccess({ data })),
            catchError(error => of(CommonActions.loadSkillsFailure({ error })))
          );
        }
      })
    );
  });
}
