import {NgModule} from '@angular/core';
import {SearchMenuComponent} from "./search-menu.component";
import {CommonModule} from "@angular/common";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {RouterModule} from "@angular/router";
import {StatusCandidateModule} from "../../../shared/ui/status-candidate/status-candidate.module";
import {
  ModalAvailabilityCandidateModule
} from "../../../shared/ui/modal-availability-candidate/modal-availability-candidate.module";
import {FormAddressModule} from "../../../shared/ui/form-address/form-address.module";
import {FormContentModule} from "../../../shared/ui/form-content/form-content.module";
import {FormJobsModule} from "../../../shared/ui/form-jobs/form-jobs.module";
import {FormRemoteModule} from "../../../shared/ui/form-remote/form-remote.module";
import {FormSchoolModule} from "../../../shared/ui/form-school/form-school.module";
import {FormSkillModule} from "../../../shared/ui/form-skill/form-skill.module";
import {FormYearExperienceModule} from "../../../shared/ui/form-year-experience/form-year-experience.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {ReactiveFormsModule} from "@angular/forms";
import {ModalSearchModule} from "../modal-search/modal-search.module";
import {SharedModule} from "../../../shared/shared.module";

@NgModule({
  imports: [CommonModule, FontAwesomeModule, RouterModule, StatusCandidateModule, ModalAvailabilityCandidateModule, FormAddressModule, FormContentModule, FormJobsModule, FormRemoteModule, FormSchoolModule, FormSkillModule, FormYearExperienceModule, NgSelectModule, ReactiveFormsModule, ModalSearchModule, SharedModule],
  declarations: [SearchMenuComponent],
  exports: [SearchMenuComponent],
})
export class SearchMenuModule {}
