import {createAction, props} from '@ngrx/store';
import {SubscriptionModel} from "../models/subscription.model";
import {PriceModel} from "../models/stripe/price.model";

export const getSubscription = createAction(
  '[Subscription] Get Subscription'
);

export const getSubscriptionSuccess = createAction(
  '[Subscription] Get Subscription Success',
  props<{ data: SubscriptionModel }>()
);

export const getSubscriptionFailure = createAction(
  '[Subscription] Get Subscription Failure',
  props<{ error: any }>()
);

export const updateSubscription = createAction(
  '[Subscription] Update Subscription',
  props<{ priceId: string }>()
);

export const updateSubscriptionSuccess = createAction(
  '[Subscription] Update Subscription Success',
  props<{ data: SubscriptionModel }>()
);

export const updateSubscriptionFailure = createAction(
  '[Subscription] Update Subscription Failure',
  props<{ error: any }>()
);

export const updateSubscriptionCancel = createAction(
  '[Subscription] Update Subscription Cancel'
);

export const updateSubscriptionCancelSuccess = createAction(
  '[Subscription] Update Subscription Cancel Success',
  props<{ data: SubscriptionModel }>()
);

export const updateSubscriptionCancelFailure = createAction(
  '[Subscription] Update Subscription Cancel Failure',
  props<{ error: any }>()
);

export const getSubscriptionPrices = createAction(
  '[Subscription] Get Subscription Prices'
);

export const getSubscriptionPricesSuccess = createAction(
  '[Subscription] Get Subscription Prices Success',
  props<{ data: PriceModel[] }>()
);

export const getSubscriptionPricesFailure = createAction(
  '[Subscription] Get Subscription Prices Failure',
  props<{ error: any }>()
);
