<span (click)="toggle()">
  <ng-content></ng-content>
</span>
<div class="notification text-start">
  <div class="offcanvas offcanvas-start"  tabindex="-1" #offCanvas>
    <ng-container *ngIf="display">
      <div class="offcanvas-header">
        <h1 class="offcanvas-title">Notifications</h1>
        <button  class="btn-close color-tertiary" (click)="toggle()"></button>
      </div>
      <div class="offcanvas-body scrollbar">
        <ng-container *ngIf="subscriber.profile_type === 'E'">
          <ul class="nav nav-tabs nav-tabs-custom mb-2" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="all-tab" data-bs-toggle="tab" data-bs-target="#all"  role="tab" aria-controls="all" aria-selected="true">Toutes</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="discussion-tab" data-bs-toggle="tab" data-bs-target="#discussion"  role="tab" aria-controls="discussion" aria-selected="false">Discussions</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="collaborators-tab" data-bs-toggle="tab" data-bs-target="#collaborators"  role="tab" aria-controls="collaborators" aria-selected="true">Interactions</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="alerts-tab" data-bs-toggle="tab" data-bs-target="#alerts"  role="tab" aria-controls="alerts" aria-selected="false">Alertes</button>
            </li>
          </ul>

          <div class="tab-content">

          </div>
        </ng-container>

        <ng-container *ngIf="subscriber.profile_type === 'C'">
          <ul class="nav nav-tabs nav-tabs-custom mb-2" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="all-tab" data-bs-toggle="tab" data-bs-target="#all"  role="tab" aria-controls="all" aria-selected="true">Toutes</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="discussion-tab" data-bs-toggle="tab" data-bs-target="#discussion"  role="tab" aria-controls="discussion" aria-selected="false">Discussions</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="proposition-tab" data-bs-toggle="tab" data-bs-target="#proposition"  role="tab" aria-controls="proposition" aria-selected="false">Nouvelles demandes</button>
            </li>
          </ul>
        </ng-container>

        <div class="tab-content" *ngIf="notifications$ | async as notifications">

          <ng-container *ngIf="notifications.length === 0">
            <div class="p-5 text-center dashed mt-5">
              <div class="d-flex justify-content-center flex-column gap-3">
                <div>
                  <fa-icon [icon]="fa.faBell" size="lg"></fa-icon>
                </div>
                <div class="fw-bold">
                  Aucune notification pour le moment
                </div>
                <div class="text-muted d-flex justify-content-center">
                  <div class="text-center">
                    <ng-container *ngIf="subscriber.profile_type === 'C'">
                      Vous recevrez un email pour chaque nouveau message reçu sur Fifty Talents
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

          </ng-container>
          <ng-container *ngIf="subscriber.profile_type === 'C'">

            <div class="tab-pane active" id="all" role="tabpanel" aria-labelledby="all-tab">
              <div class="d-flex justify-content-end mb-2" *ngIf="notifications.length !== 0">
                <div class="btn btn-outline-secondary btn-sm" (click)="markAsAllRead()">Marquer comme lu</div>
              </div>

              <div [ngClass]="{'unread': notification.read_at === null}" class="card" *ngFor="let notification of notifications" (click)="markAsRead(notification)">
                <app-notification-item [notification]="notification"></app-notification-item>
              </div>
            </div>
            <div class="tab-pane" id="discussion" role="tabpanel" aria-labelledby="discussion-tab">
              <div [ngClass]="{'unread': notification.read_at === null}" class="card" *ngFor="let notification of notifications | filter: 'target' : 'message'" (click)="markAsRead(notification)">
                <app-notification-item [notification]="notification"></app-notification-item>
              </div>
            </div>
            <div class="tab-pane" id="proposition" role="tabpanel" aria-labelledby="proposition-tab">
              <div [ngClass]="{'unread': notification.read_at === null}" class="card" *ngFor="let notification of notifications | filter: 'target' : 'proposition'" (click)="markAsRead(notification)">
                <app-notification-item [notification]="notification"></app-notification-item>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="subscriber.profile_type === 'E'">

            <div class="tab-pane active" id="all" role="tabpanel" aria-labelledby="all-tab">
              <div class="d-flex justify-content-end mb-2" *ngIf="notifications.length !== 0">
                <div class="btn btn-outline-secondary btn-sm" (click)="markAsAllRead()">Marquer comme lu</div>
              </div>

              <div [ngClass]="{'unread': notification.read_at === null}" class="card" *ngFor="let notification of notifications" (click)="markAsRead(notification)">
                <app-notification-item [notification]="notification"></app-notification-item>
              </div>
            </div>
            <div class="tab-pane" id="discussion" role="tabpanel" aria-labelledby="discussion-tab">
              <div [ngClass]="{'unread': notification.read_at === null}" class="card" *ngFor="let notification of notifications | filter: 'target' : 'message'" (click)="markAsRead(notification)">
                <app-notification-item [notification]="notification"></app-notification-item>
              </div>
            </div>
            <div class="tab-pane" id="collaborators" role="tabpanel" aria-labelledby="collaborators-tab">
              <div [ngClass]="{'unread': notification.read_at === null}" class="card" *ngFor="let notification of notifications | filter: 'target' : 'interactions'" (click)="markAsRead(notification)">
                <app-notification-item [notification]="notification"></app-notification-item>
              </div>
            </div>
            <div class="tab-pane" id="alerts" role="tabpanel" aria-labelledby="alerts-tab">
              <div [ngClass]="{'unread': notification.read_at === null}" class="card" *ngFor="let notification of notifications | filter: 'target' : 'alert'" (click)="markAsRead(notification)">
                <app-notification-item [notification]="notification"></app-notification-item>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
