import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import {LabelType, Options} from "@angular-slider/ngx-slider";
import {Subject, take, takeUntil} from "rxjs";

@Component({
  selector: 'app-form-remote',
  templateUrl: './form-remote.component.html',
  styleUrls: ['./form-remote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormRemoteComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: FormRemoteComponent,
    }
  ]
})
export class FormRemoteComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {

  @Input()
  form: FormGroup

  @Input()
  checkbox: boolean = false

  @Input()
  displaySlider: boolean = true

  @Input()
  unity: string = 'jours'

  fa = {}

  displayCustomRemote = false

  formDay: FormControl = new FormControl([1, 2]);

  optionsDays: Options = {
    floor: 0,
    ceil: 5,
    draggableRangeOnly: false,
    showTicksValues: true,
    showTicks: false,
    tickStep: 1,
    tickValueStep: 1,
    translate: (value: number, label: LabelType): string => {
      return ''
    },
    getLegend: (value: number): string => {
      return value + ' ' + '<span class="d-none d-md-inline">' + this.unity + '</span>';
    }
  };

  ngUnsubscribe = new Subject<void>();

  constructor(private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    // this.formDay.patchValue([this.remoteMin.value, this.remoteMax.value])
    if (this.checkbox === true) {
      if (this.remote.value.find((value: number) => value === 2) !== undefined) {
        this.displayCustomRemote = true
      } else {
        this.displayCustomRemote = false
      }
      this.remote.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((remote) => {
        if (remote.find((value: number) => value === 2) !== undefined) {
          this.displayCustomRemote = true
        } else {
          this.displayCustomRemote = false
        }
        this.ref.markForCheck()
      })
    } else {
      if (this.remote.value === 2 && this.displaySlider) {
        this.displayCustomRemote = true
        this.ref.markForCheck()
      }
      this.remote.valueChanges.subscribe((value) => {
        this.displayCustomRemote = value === 2 && this.displaySlider
        this.ref.markForCheck()
      })
    }

    this.form.valueChanges.pipe(take(1)).subscribe(() => {
      this.formDay.patchValue([this.remoteMin.value, this.remoteMax.value])
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngAfterViewInit(): void {
  }

  public onTouched: () => void = () => {};

  public writeValue(v: any) {
    this.ref.markForCheck()
  }

  public setDisabledState(disabled: boolean) {
    disabled ? this.form.disable() : this.form.enable();
  }

  registerOnChange(fn: any): void {
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  public validate(value: any): ValidationErrors | null {

    // if (this.isFavorite === false) {
    //   return null
    // }
    // if (value.value.length !== 0 && this.isFavorite) {
    //   return null
    // }

    this.ref.markForCheck()
    return value.errors;
  }

  updateMinDay(value: any, emitEvent: boolean = true) {
    this.form.patchValue({remote_min: value}, { emitEvent: emitEvent })
  }

  updateMaxDay(value: any, emitEvent: boolean = true) {
    this.form.patchValue({remote_max: value}, { emitEvent: emitEvent })
  }

  onCheckboxChange(e: any, checkArray: AbstractControl) {
    let tmp: any[] = [...checkArray.value]

    this.form.markAsTouched()
    if (e.target.checked) {
      tmp.push(parseInt(e.target.value));
    } else {
      let i: number = 0;
      tmp.forEach((item: any) => {
        if (item == e.target.value) {
          tmp.splice(i, 1)
          return;
        }
        i++;
      });
    }
    this.remote.patchValue(tmp)
  }

  isSelected(value: any, checkArray: AbstractControl) {
    let selected = false

    let tmp: any[] = checkArray.value

    tmp.forEach((item: any) => {
      if (item == value) {
        selected = true
      }
    });
    return selected
  }

  remove(remoteToBeDeleted: number) {
    let remotes = [...this.remote.value]

    let remoteFound = remotes.findIndex((remote: any) => remote === remoteToBeDeleted)
    if (remoteFound !== -1) {
      remotes.splice(remoteFound, 1)
    }

    this.remote.setValue(remotes)
  }

  get remote(): AbstractControl {
    return this.form.get('remote')!;
  }

  get remoteMin(): AbstractControl {
    return this.form.get('remote_min')!;
  }

  get remoteMax(): AbstractControl {
    return this.form.get('remote_max')!;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
