import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";

const API_URL = environment.API_URL;
const PATH = '/jobOffer/'

@Injectable({
  providedIn: 'root'
})
export class JobOfferService {

  constructor(private http: HttpClient) {
  }

  index() {
    return this.http.get<any>(API_URL + PATH)
  }
}
