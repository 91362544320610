import {Directive, ElementRef, Renderer2, OnInit, OnDestroy, Input} from '@angular/core';

@Directive({
  selector: '[appLoadScript]'
})
export class LoadScriptDirective implements OnInit, OnDestroy {

  @Input('appLoadScript') scriptUrl: string;
  private script: HTMLScriptElement;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    if (this.scriptUrl) {
      this.script = this.renderer.createElement('script');
      this.script.type = 'text/javascript';
      this.script.src = this.scriptUrl;
      this.renderer.appendChild(this.el.nativeElement, this.script);
    }
  }

  ngOnDestroy(): void {
    if (this.script) {
      this.renderer.removeChild(this.el.nativeElement, this.script);
    }
  }
}
