import {createReducer, on} from '@ngrx/store';
import * as PropositionActions from '../actions/proposition.actions';
import {PropositionModel} from "../models/proposition.model";

export const CommonFeatureKey = 'proposition';

export interface State {
  current: PropositionModel | null,
  loading: boolean,
  error: any,
  all: PropositionModel[]
}

export const initialState: State = {
  current: null,
  loading: false,
  error: null,
  all: []
};

export const reducer = createReducer(
  initialState,
  on(PropositionActions.loadPropositions, (state) => ({...state, loading: true, error: null})),
  on(PropositionActions.loadPropositionsSuccess, (state, {propositions}) => ({
    ...state,
    all: propositions,
    loading: false,
    error: null
  })),
  on(PropositionActions.loadPropositionsFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PropositionActions.getProposition, (state) => ({...state, loading: true, error: null})),
  on(PropositionActions.getPropositionSuccess, (state, {proposition}) => ({
    ...state,
    current: proposition,
    loading: false,
    error: null
  })),
  on(PropositionActions.getPropositionFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PropositionActions.getPropositionWithMessages, (state) => ({...state, loading: true, error: null})),
  on(PropositionActions.getPropositionWithMessagesSuccess, (state, {proposition}) => ({
    ...state,
    current: proposition,
    loading: false,
    error: null
  })),
  on(PropositionActions.getPropositionWithMessagesFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PropositionActions.storeProposition, (state) => ({...state, loading: true, error: null})),
  on(PropositionActions.storePropositionSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(PropositionActions.storePropositionFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PropositionActions.storePropositionMessage, (state) => ({...state, loading: true, error: null})),
  on(PropositionActions.storePropositionMessageSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(PropositionActions.storePropositionMessageFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PropositionActions.acceptProposition, (state) => ({...state, loading: true, error: null})),
  on(PropositionActions.acceptPropositionSuccess, (state, {proposition}) => ({
    ...state,
    current: proposition,
    loading: false,
    error: null
  })),
  on(PropositionActions.acceptPropositionFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PropositionActions.refuseProposition, (state) => ({...state, loading: true, error: null})),
  on(PropositionActions.refusePropositionSuccess, (state, {proposition}) => ({
    ...state,
    current: {...proposition},
    loading: false,
    error: null
  })),
  on(PropositionActions.refusePropositionFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PropositionActions.resetCurrentProposition, (state, {type}) => ({
    ...state,
    current: null,
    loading: false,
    error: null
  })),
);
