import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as PropositionActions from '../actions/proposition.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of, tap} from 'rxjs';
import {Store} from "@ngrx/store";
import {PropositionService} from "../apis/proposition.service";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class PropositionEffects {
  constructor(private actions$: Actions, private propositionService: PropositionService, private store: Store,
              private toast: ToastrService) {
  }

  load$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PropositionActions.loadPropositions),
        map((action: any) => action),
        mergeMap(() => {
          return this.propositionService.load().pipe(
            map(propositions => PropositionActions.loadPropositionsSuccess({propositions})),
            catchError(error => of(PropositionActions.loadPropositionsFailure({error})))
          );
        })
      )
    }
  );

  getProposition$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PropositionActions.getProposition),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.propositionService.get(id).pipe(
            map((proposition) => PropositionActions.getPropositionSuccess({proposition})),
            catchError(error => of(PropositionActions.getPropositionFailure({error})))
          );
        })
      )
    }
  );

  getPropositionWithMessages$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PropositionActions.getPropositionWithMessages),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.propositionService.getWithMessages(id).pipe(
            map((proposition) => PropositionActions.getPropositionWithMessagesSuccess({proposition})),
            catchError(error => of(PropositionActions.getPropositionWithMessagesFailure({error})))
          );
        })
      )
    }
  );

  storeProposition$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PropositionActions.storeProposition),
        map((action: any) => action),
        mergeMap(({cProfileId, JobOfferId, message, salary_min, salary_max}) => {
          return this.propositionService.create(cProfileId, JobOfferId, message, salary_min, salary_max).pipe(
            map((data) => PropositionActions.storePropositionSuccess({data})),
            catchError(error => of(PropositionActions.storePropositionFailure({error})))
          );
        })
      )
    }
  );

  storePropositionMessage$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PropositionActions.storePropositionMessage),
        map((action: any) => action),
        mergeMap(({id, message}) => {
          return this.propositionService.message(id, message).pipe(
            map((data) => PropositionActions.storePropositionMessageSuccess({data})),
            catchError(error => of(PropositionActions.storePropositionMessageFailure({error})))
          );
        })
      )
    }
  );

  accepteProposition$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PropositionActions.acceptProposition),
        map((action: any) => action),
        mergeMap(({proposition}) => {
          return this.propositionService.accept(proposition).pipe(
            map((proposition) => PropositionActions.acceptPropositionSuccess({proposition})),
            catchError(error => of(PropositionActions.acceptPropositionFailure({error})))
          );
        })
      )
    }
  );

  acceptPropositionSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PropositionActions.acceptPropositionSuccess),
        tap(({proposition}) => {
          this.toast.success('Un message va être envoyé à l\'entreprise mais vous pouvez prendre les devants', 'Confirmation')
        }),
      );
    },
    {dispatch: false}
  );

  refuseProposition$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PropositionActions.refuseProposition),
        map((action: any) => action),
        mergeMap(({proposition, reason, other_reason}) => {
          return this.propositionService.refuse(proposition, reason, other_reason).pipe(
            map((proposition) => PropositionActions.refusePropositionSuccess({proposition})),
            catchError(error => of(PropositionActions.refusePropositionFailure({error})))
          );
        })
      )
    }
  );

  refusePropositionSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PropositionActions.refusePropositionSuccess),
        tap(({proposition}) => {
          this.toast.success('Nous allons en informer l\'entreprise', 'Confirmation')
        }),
      );
    },
    {dispatch: false}
  );
}
