import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent implements OnInit {

  @Input()
  loading: any = false

  @Input()
  class: string = ''

  @Input()
  type: string = ''

  @Input()
  disabled: boolean = false

  @Input()
  click: any = () => {}

  constructor() {}

  ngOnInit() {
  }
}
