import {createAction, props} from '@ngrx/store';
import {CProfileModel} from "../models/cProfile.model";

export const loadSearch = createAction(
  '[Search] Load Search',
  props<{ body: any }>()
);

export const loadSearchSuccess = createAction(
  '[Search] Load Search Success',
  props<{ data: any }>()
);

export const loadSearchFailure = createAction(
  '[Search] Load Search Failure',
  props<{ error: any }>()
);

export const resetSearch = createAction(
  '[Search] Reset Search'
);

export const loadSearchNextPage = createAction(
  '[Search] Load Search Next Page'
);

export const updateSearchSortAndFilter = createAction(
  '[Search] Update Search Sort & Filter',
  props<{ filter: any, sort: any }>()
);

export const updateSearchCProfileFavorite = createAction(
  '[Search] Update Search CProfile Filter',
  props<{ cProfile: CProfileModel}>()
);
