export const environment = {
  production: true,
  NAME: 'production',

  API_URL: 'https://api.fiftytalents.com/app',
  APP_URL: 'https://app.fiftytalents.com/',
  PUBLIC_URL: 'https://www.fiftytalents.com/',
  DATA_URL: 'https://api.fiftytalents.com/data/',
  API_OAUTH_URL: 'https://api.fiftytalents.com/oauth/token',
  OAUTH_CLIENT: '28',
  OAUTH_SECRET: 'LPxhee2PuzMywEkGogl2QoBPk6YmqVgztgcNIutd',

  PLAN_3: 'price_1PNaCrApkQDLUd1wwoaerZWK',
  PLAN_6: 'price_1PNaCvApkQDLUd1wYHMMvgvF'
};
