import {createReducer, on} from '@ngrx/store';
import * as PixelSearchActions from '../actions/pixelSearch.actions';
import {PixelSearchModel} from "../models/pixelSearch.model";

export const PixelSearchFeatureKey = 'pixelSearch';

export interface State {
  current: PixelSearchModel | null,
  loading: boolean,
  loadingEstimation: boolean,
  error: any
  all: PixelSearchModel[],
}

export const initialState: State = {
  current: null,
  loading: false,
  loadingEstimation: false,
  error: null,
  all: []
};

export const reducer = createReducer(
  initialState,
  on(PixelSearchActions.loadPixelSearch, (state) => ({...state, loading: true, error: null})),
  on(PixelSearchActions.loadPixelSearchSuccess, (state, {data}) => ({
    ...state,
    all: data,
    loading: false,
    error: null
  })),
  on(PixelSearchActions.updatePixelSearchFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PixelSearchActions.getEstimatePixelSearch, (state) => ({...state, loadingEstimation: true, error: null})),
  on(PixelSearchActions.getEstimatePixelSearchSuccess, (state, {data}) => ({
    ...state,
    loadingEstimation: false,
    error: null
  })),
  on(PixelSearchActions.getEstimatePixelSearchFailure, (state, {error}) => ({...state, loadingEstimation: false, error})),
  on(PixelSearchActions.createPixelSearch, (state) => ({...state, loading: true, error: null})),
  on(PixelSearchActions.createPixelSearchSuccess, (state, {data}) => ({
    ...state,
    loading: false,
    error: null
  })),
  on(PixelSearchActions.createPixelSearchFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PixelSearchActions.updatePixelSearch, (state) => ({...state, loading: true, error: null})),
  on(PixelSearchActions.updatePixelSearchSuccess, (state, {data}) => ({
    ...state,
    loading: false,
    error: null
  })),
  on(PixelSearchActions.updatePixelSearchFailure, (state, {error}) => ({...state, loading: false, error})),
);
