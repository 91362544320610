import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import {faCaretDown, faSearch} from "@fortawesome/pro-light-svg-icons";
import {faCircleXmark} from "@fortawesome/pro-regular-svg-icons";
import {DepartmentsStore} from "../../data-access/departments.store";

@Component({
  selector: 'app-form-content',
  templateUrl: './form-content.component.html',
  styleUrls: ['./form-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  providers: [DepartmentsStore,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormContentComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: FormContentComponent,
    },
  ],
})
export class FormContentComponent implements OnInit, AfterViewInit, ControlValueAccessor, Validator {

  fa = {faCircleXmark, faSearch, faCaretDown}

  @ViewChild('content')
  content: ElementRef

  @Input()
  formControl!: FormControl;

  @Input('placeholder')
  placeholder: string = 'Recherche globale'

  @Input()
  displayPreview: boolean = true

  constructor(private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    // @ts-ignore
    this.formControl['_markAsTouched'] = this.formControl.markAsTouched;
    this.formControl.markAsTouched = () => {
      // @ts-ignore
      this.formControl['_markAsTouched']();
      // this.formControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      this.ref.markForCheck()
    }
  }

  ngAfterViewInit(): void {
  }

  public onTouched: () => void = () => {};

  public writeValue(v: any) {
    this.ref.markForCheck()
  }

  public setDisabledState(disabled: boolean) {
    disabled ? this.formControl.disable() : this.formControl.enable();
  }

  registerOnChange(fn: any): void {
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  public validate(value: any): ValidationErrors | null {

    this.ref.markForCheck()
    return value.errors;
  }
  addContent(content: string) {
    if (content) {
      let tmpContent: any[] = this.formControl.value
      if (!tmpContent) {
        tmpContent = []
      }

      this.formControl.patchValue([...tmpContent, content])
      if (content) {
        // @ts-ignore
        this.content.nativeElement.value = ''
      }
    }
  }

  removeContent(content: any) {
    if (content) {
      let tmpContent: any[] = this.formControl.value

      let index = tmpContent.findIndex((index: any) => {
        return index == content
      })
      if (index !== -1) {
        tmpContent.splice(index, 1)
      }
      this.formControl.patchValue(tmpContent)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ref.markForCheck()
  }
}
