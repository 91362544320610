import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from "@angular/forms";
import {LabelType, Options} from "@angular-slider/ngx-slider";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-form-year-experience',
  templateUrl: './form-year-experience.component.html',
  styleUrls: ['./form-year-experience.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormYearExperienceComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: FormYearExperienceComponent,
    }
  ]

})
export class FormYearExperienceComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, ControlValueAccessor, Validator {

  @Input()
  form: FormGroup

  @Input()
  min: number = 1

  @Input()
  max: number = 2

  @Input()
  ticksStep: number = 1

  fa = {}
  formExperience: FormControl = new FormControl([2, 6]);

  optionsYears: Options
  ngUnsubscribe = new Subject<void>();

  constructor(private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.optionsYears = {
      floor: this.min,
      ceil: this.max,
      draggableRangeOnly: false,
      showTicksValues: true,
      tickStep: this.ticksStep,
      tickValueStep: this.ticksStep,
      translate: (value: number, label: LabelType): string => {
        return ''
      },
      getLegend: (value: number): string => {
        if (value >= 8) {
          return '+8'
        }
        return value + '';
      }
    };
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.formExperience.patchValue([this.experienceMin.value, this.experienceMax.value], {emitEvent: false})
      this.ref.markForCheck()
    })
  }

  ngAfterViewInit(): void {
    this.formExperience.patchValue([this.experienceMin.value, this.experienceMax.value], {emitEvent: false})
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['form']) {
      this.formExperience.patchValue([this.experienceMin.value, this.experienceMax.value], {emitEvent: false})
    }
  }

  updateMinLevel(value: any, emitEvent: boolean = true) {
    if (this.experienceMin.value !== value) {
      this.form.patchValue({experience_min: value}, { emitEvent: emitEvent })
    }
  }

  updateMaxLevel(value: any, emitEvent: boolean = true) {
    if (this.experienceMax.value !== value) {
      this.form.patchValue({experience_max: value}, {emitEvent: emitEvent})
    }
  }

  public onTouched: () => void = () => {};

  public writeValue(v: any) {
    this.ref.markForCheck()
  }

  public setDisabledState(disabled: boolean) {
    disabled ? this.form.disable() : this.form.enable();
  }

  registerOnChange(fn: any): void {
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  public validate(value: any): ValidationErrors | null {

    // if (this.isFavorite === false) {
    //   return null
    // }
    // if (value.value.length !== 0 && this.isFavorite) {
    //   return null
    // }

    this.ref.markForCheck()
    return value.errors;
  }
  get experienceMin(): AbstractControl {
    return this.form.get('experience_min')!;
  }

  get experienceMax(): AbstractControl {
    return this.form.get('experience_max')!;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
