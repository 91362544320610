import {ChangeDetectorRef, Injectable} from "@angular/core";
import {Store} from "./store";

@Injectable()
export class GlobalStore extends Store<any|null> {

  constructor (protected ref: ChangeDetectorRef) {
    super({
      isPdf: false
    });
  }

  setIsPdf(isPdf: boolean = true): void {
      this.setState({...this.state$, isPdf: isPdf})
      this.ref.markForCheck()
  }
}
