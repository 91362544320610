import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {SkillModel} from "../models/skill.model";


const API_URL = environment.API_URL;
const PATH = '/pixelSearch/'

@Injectable({
  providedIn: 'root'
})
export class PixelSearchService {

  constructor(private http: HttpClient) {
  }

  load() {
    return this.http.get<any>(API_URL + PATH)
  }

  create(pixelSearchId: string, skills: [], experience_min: number, experience_max: number, salary_min: number, salary_max: number) {
    return this.http.post<any>(API_URL + PATH, {skills, experience_min, experience_max, salary_min, salary_max})
  }

  update(pixelSearchId: string, skills: [], experience_min: number, experience_max: number, salary_min: number, salary_max: number) {
    return this.http.patch<any>(API_URL + PATH + pixelSearchId, {skills, experience_min, experience_max, salary_min, salary_max})
  }

  estimate(skills: SkillModel[], experience_min: number, experience_max: number, salary_min: number|null, salary_max: number|null) {
    return this.http.post<any>(API_URL + PATH + 'estimate', {skills, experience_min, experience_max, salary_min, salary_max})
  }
}
