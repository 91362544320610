<div class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header d-flex justify-content-between align-items-center">
    <div class="d-flex gap-2 align-items-center">
      <fa-icon [icon]="fa.faCircleCheck" *ngIf="toastPackage.toastType === 'toast-success'" size="lg"></fa-icon>
      <fa-icon [icon]="fa.faCircleXmark" *ngIf="toastPackage.toastType === 'toast-error'" size="lg"></fa-icon>
      <fa-icon [icon]="fa.faCircleInfo" *ngIf="toastPackage.toastType === 'toast-info'" size="lg"></fa-icon>
      <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
        {{title}}
      </div>
    </div>
    <div class="pointer" data-bs-dismiss="toast" aria-label="Close" (click)="remove()"><fa-icon [icon]="fa.faXmark"></fa-icon></div>
  </div>
  <div class="toast-body">
    <div *ngIf="message && options.enableHtml" role="alert"
         [class]="options.messageClass" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" role="alert"
         [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>
</div>
