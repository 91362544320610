import {ChangeDetectorRef, Injectable} from "@angular/core";
import {Store} from "./store";
import {JobsService} from "./jobs.service";
import {NotificationService} from "./notification.service";

@Injectable({
  providedIn: 'root',
})
export class NotificationsStore extends Store<[]|null> {

  constructor (private notificationService: NotificationService, private ref: ChangeDetectorRef) {
    super(null);
  }

  get(): void {
    this.notificationService.getAll().subscribe(data => {
      this.setState(data);
      this.ref.markForCheck()
    });
  }

  read(id: string): void {
    this.notificationService.read(id).subscribe(data => {
      this.setState(data);
      this.ref.markForCheck()
    });
  }

  readAll(): void {
    this.notificationService.readAll().subscribe(data => {
      this.setState(data);
      this.ref.markForCheck()
    });
  }
}
