import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromEProfileReducer from '../reducers/eProfile.reducer';

export const getEProfileState = createFeatureSelector<fromEProfileReducer.State>('eProfile');

export const loading = createSelector(getEProfileState, (state: fromEProfileReducer.State) => state.loading);

export const selectError = createSelector(getEProfileState, (state: fromEProfileReducer.State) => state.error);

export const selectMe = createSelector(getEProfileState, (state: fromEProfileReducer.State) => state.me);

export const selectQuota = createSelector(getEProfileState, (state: fromEProfileReducer.State) => state.quota);

export const selectPushes = createSelector(getEProfileState, (state: fromEProfileReducer.State) => state.pushes);

export const selectBusiness = createSelector(getEProfileState, (state: fromEProfileReducer.State) => state.business);

export const selectInvoices = createSelector(getEProfileState, (state: fromEProfileReducer.State) => state.invoices);
