import {ChangeDetectorRef, Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appScrollTabs]'
})
export class ScrollTabsDirective {

  clicked: any = null

  constructor(private el: ElementRef, private ref: ChangeDetectorRef) {
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    this.clicked = event.target

    let toScroll = 0
    if (this.clicked.parentElement) {
      const offset = this.clicked.parentElement.offsetLeft
      if (this.clicked.parentElement.parentElement) {
        toScroll = offset - (window.innerWidth / 2) + 100

        const id = '#' + this.clicked.parentElement.parentElement.id
        if (id.length > 1) {
          document.querySelectorAll(id).forEach((t, i) => {
            let element = t as HTMLElement
            element.scrollLeft = toScroll

            document.querySelectorAll(id + ' button[data-bs-toggle="tab"]').forEach((e) => {
              let element = e as HTMLElement
              if (element.classList.contains('active')) {
                element.classList.remove('active')
              }
            })

            document.querySelectorAll(id + ' button[data-bs-toggle="tab"]#' + this.clicked.id).forEach((e) => {
              let element = e as HTMLElement
              if (!element.classList.contains('active')) {
                // const tab = element.getAttribute('data-bs-target')
                // if (tab) {
                //   document.querySelector(tab)?.scrollIntoView({
                //     behavior: "smooth",
                //     block: "start",
                //     inline: "nearest"
                //   });
                // }

                element.classList.add('active')
              }
            })
          })
        }
      }
    }
  }
}
