import {createAction, props} from '@ngrx/store';
import {PropositionModel} from "../models/proposition.model";

export const loadPropositions = createAction(
  '[Proposition] Load propositions'
);

export const loadPropositionsSuccess = createAction(
  '[Proposition] Load propositions Success',
  props<{ propositions: PropositionModel[] }>()
);

export const loadPropositionsFailure = createAction(
  '[Proposition] Load propositions Failure',
  props<{ error: any }>()
);

export const getProposition = createAction(
  '[Proposition] Get proposition',
  props<{ id: number }>()
);

export const getPropositionSuccess = createAction(
  '[Proposition] Get proposition Success',
  props<{ proposition: PropositionModel }>()
);

export const getPropositionFailure = createAction(
  '[Proposition] Get proposition Failure',
  props<{ error: any }>()
);

export const getPropositionWithMessages = createAction(
  '[Proposition] Get proposition With Messages',
  props<{ id: number }>()
);

export const getPropositionWithMessagesSuccess = createAction(
  '[Proposition] Get proposition With Messages Success',
  props<{ proposition: PropositionModel }>()
);

export const getPropositionWithMessagesFailure = createAction(
  '[Proposition] Get proposition With Messages Failure',
  props<{ error: any }>()
);

export const storeProposition = createAction(
  '[Proposition] Store proposition',
  props<{ cProfileId: string, JobOfferId: string, message: string | null, salary_min: number, salary_max: number }>()
);

export const storePropositionSuccess = createAction(
  '[Proposition] Store proposition Success',
  props<{ data: PropositionModel }>()
);

export const storePropositionFailure = createAction(
  '[Proposition] Store proposition Failure',
  props<{ error: any }>()
);

export const storePropositionMessage = createAction(
  '[Proposition] Store proposition Message',
  props<{ id: number, message: string }>()
);

export const storePropositionMessageSuccess = createAction(
  '[Proposition] Store proposition Message Success',
  props<{ data: PropositionModel }>()
);

export const storePropositionMessageFailure = createAction(
  '[Proposition] Store proposition Message Failure',
  props<{ error: any }>()
);

export const refuseProposition = createAction(
  '[Proposition] Refuse proposition',
  props<{ proposition: PropositionModel, reason: number, other_reason: string}>()
);

export const refusePropositionSuccess = createAction(
  '[Proposition] Refuse proposition Success',
  props<{ proposition: PropositionModel }>()
);

export const refusePropositionFailure = createAction(
  '[Proposition] Refuse proposition Failure',
  props<{ error: any }>()
);

export const acceptProposition = createAction(
  '[Proposition] Accept proposition',
  props<{ proposition: PropositionModel }>()
);

export const acceptPropositionSuccess = createAction(
  '[Proposition] Accept proposition Success',
  props<{ proposition: PropositionModel }>()
);

export const acceptPropositionFailure = createAction(
  '[Proposition] Accept proposition Failure',
  props<{ error: any }>()
);

export const resetCurrentProposition = createAction(
  '[Proposition] Reset Current Proposition'
);
